import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getRentSummaryChartData } from '../../../actions/property/RentSummaryChartApi';

const initialState = {
  error: false,
  isLoading: false,
  data: [],
};

export const getRentSummaryChartDataAsync = createAsyncThunk('property/fetchRentChartData', async (obj) => {
  const response = await getRentSummaryChartData(obj);
  return response;
});

export const PropertyRentChartDataSlice = createSlice({
  name: 'Property Rent Chart',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRentSummaryChartDataAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getRentSummaryChartDataAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
      })
      .addCase(getRentSummaryChartDataAsync.rejected, (state) => {
        state.isLoading = false;
        state.error = true;
      });
  },
});

export const selectRentChartData = (state) => state.propertyRentChart.data;
export const selectRentChartDataLoading = (state) => state.propertyRentChart.isLoading;

export default PropertyRentChartDataSlice.reducer;

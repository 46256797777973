import React, { useEffect, useRef, useState } from 'react';
import '../../../../assets/less/index.less';
import watsappIcon from '../../../../assets/images/watsappIcon.svg';

import card1 from '../../../../assets/card1.png';
import card2 from '../../../../assets/card2.png';
import card3 from '../../../../assets/card3.png';
import card4 from '../../../../assets/card4.png';

import PropertyIcon from '../../../../assets/images/statsProperty.svg';

import { GetPropertyNotificationData } from '../../../../redux/actions/dashboard/getDashboardData.action';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Spin, notification, Button, Row, Col, List, Popconfirm, Segmented, Card } from 'antd';
import { DisconnectOutlined, LinkOutlined, NotificationOutlined, HomeOutlined, SettingOutlined, RightCircleFilled } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { getTitle, getIcon } from '../../../common/NotificationUtil';
import NoProperty from '../../../common/NoProperty';
import { notApplicableDepreciationAsync } from '../../../../redux/reducers/slices/Depreciation/DepreciationSlice';
import { LoanReject } from '../../../../redux/actions/dashboard/loanReject.action';

const ClientDashboard = ({ isTaxAccountant = false }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const ref = useRef();
  const [notificationLoading, setNotificationLoading] = useState(false);
  const [isOpenEmail, setIsOpenEmail] = useState(false);
  const [propertyAddress, setPropertyAddress] = useState({});
  const [propertyNotificationData, setPropertyNotificationData] = useState([]);
  const [viewValue, setViewValue] = useState('process');

  const clientDetail = useSelector((state) => state.getClientdetailByIdReducer);

  const clientVirtualEmail = clientDetail?.data?.result?.virtual_email;

  const propertyNotificationDta = useSelector((state) => state.getPropertyNotificationDataReducer);
  const admin = JSON.parse(localStorage.getItem('admin'));
  const currentClient = JSON.parse(localStorage.getItem('currentClient'));

  const dashboardStats = propertyNotificationDta?.data?.result?.statistics;

  const statItems = [
    {
      title: 'Total Properties',
      icon: PropertyIcon,
      value: dashboardStats?.total_propertys || 0,
      card: card1,
      antIcon: false,
      className: 'pera_icon',
    },

    {
      title: 'Pending Tasks',
      antIcon: true,
      icon: (
        <NotificationOutlined
          style={{
            fontSize: '20px',
            marginTop: '15px',
            color: '#7c67e6',
            cursor: 'pointer',
          }}
        />
      ),
      value: dashboardStats?.total_pending_actions || 0,
      card: card2,
      className: 'pera_icon1',
    },

    {
      title: 'Bank(s) Connected',
      className: 'pera_icon3',
      antIcon: true,
      icon: (
        <LinkOutlined
          style={{
            fontSize: '20px',
            marginTop: '15px',
            color: '#56C36E',
            cursor: 'pointer',
          }}
        />
      ),
      value: dashboardStats?.total_banks_connected || 0,
      card: card4,
    },

    {
      title: 'Bank(s) Not Connected',
      antIcon: true,
      className: 'pera_icon2',

      icon: (
        <DisconnectOutlined
          style={{
            fontSize: '20px',
            marginTop: '15px',
            color: '#F35C5C',
            cursor: 'pointer',
          }}
        />
      ),
      value: dashboardStats?.total_banks_disconnected || 0,
      card: card3,
    },
  ];

  // Initial api call
  useEffect(() => {
    dispatch(GetPropertyNotificationData(currentClient));
  }, [dispatch, currentClient]);

  useEffect(() => {
    if (location?.state?.reload_change) {
      dispatch(GetPropertyNotificationData(currentClient));
    }
  }, [location.state, currentClient, dispatch]);

  // Set property notification stastics reducer data to state
  useEffect(() => {
    setNotificationLoading(propertyNotificationDta.isLoading || false);
    if (propertyNotificationDta && propertyNotificationDta?.data?.result?.Data && propertyNotificationDta.error === false) {
      setPropertyNotificationData(propertyNotificationDta?.data?.result?.Data);
    }
  }, [propertyNotificationDta]);

  const handleNotApplicable = async (id) => {
    try {
      const res = await dispatch(notApplicableDepreciationAsync({ id, currentClient }));
      if (res?.payload?.data?.success) {
        notification.success({
          message: 'Success',
          description: res?.payload?.data?.message || 'Depreciation marked as not applicable!',
        });
        await dispatch(GetPropertyNotificationData(currentClient));
      } else {
        notification.error({
          message: 'Failure',
          description: res?.payload?.data?.message || 'Something went wrong!',
        });
      }
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error.message || 'Something went wrong!',
      });
    }
  };

  const NOLoan = async (id) => {
    setNotificationLoading(true);
    await dispatch(LoanReject(id, currentClient)).then((res) => {
      if (res.payload.success === true) {
        notification.success({
          message: 'Success',
          description: res.payload.message,
        });
      }
      setNotificationLoading(false);
    });
    await dispatch(GetPropertyNotificationData(currentClient));
  };

  const renderItem = (item) => {
    if (item.type === 'check_deprecation_mail') {
      return <div key={crypto.randomUUID()} className="my-3 my-sm-0"></div>;
    } else if (item.type === 'check_settlement_mail') {
      return <div key={crypto.randomUUID()} className="my-3 my-sm-0"></div>;
    } else if (item.type === 'check_investment_rent_mail') {
      return (
        <div key={crypto.randomUUID()} className="my-3 my-sm-0">
          {item.type ? (
            <Button
              className="email_btn"
              onClick={() => {
                setIsOpenEmail(true);
                setPropertyAddress(item);
              }}
            >
              Email Now
            </Button>
          ) : (
            ''
          )}
        </div>
      );
    } else if (item.type === 'add_property') {
      return (
        <div key={crypto.randomUUID()} className="my-3 my-sm-0">
          <Button onClick={() => navigate(`/clients/client/property/add`, { state: { fromSameSite: true } })}>Add</Button>
        </div>
      );
    } else if (item.type === 'check_audit_trails_category') {
      return (
        <div key={crypto.randomUUID()} className="my-3 my-sm-0">
          <Button onClick={() => navigate(`/clients/client/audit-trail`, { state: { fromSameSite: true } })}>Review Now</Button>
        </div>
      );
    } else if (item.type === 'add_product_request') {
      return (
        <>
          <Button
            className="mr-2"
            onClick={() => {
              navigate(`/clients/client/property/${item.property_id}?tab=depreciation`, {
                state: { fromSameSite: true, transaction_id: item.id },
              });
            }}
          >
            Yes
          </Button>
          <Popconfirm title="Are you sure!" onConfirm={() => handleNotApplicable(item.id)} okText="Yes, Sure" cancelText="No">
            <Button onClick={(e) => e.stopPropagation()}>No</Button>
          </Popconfirm>
        </>
      );
    } else if (item.type === 'check_property_loan') {
      return (
        <div key={crypto.randomUUID()} className="my-3 my-sm-0">
          {!item.loan_id ? (
            <>
              <Button className="mr-2" onClick={() => navigate(`/clients/client/property/edit/${item.property_id}`, { state: { fromSameSite: true } })}>
                Update
              </Button>
              {viewValue !== 'process' && <Button onClick={() => NOLoan(item.property_id)}>Loan Not Applicable</Button>}
            </>
          ) : null}
        </div>
      );
    } else {
      return '';
    }
  };

  const copyText = () => {
    navigator.clipboard.writeText(ref.current.innerHTML.replace(/<br ?\/?>/g, '\n'));

    notification.success({
      message: 'Success',
      description: 'Text copied.',
    });
  };

  const emailText = () => {
    var encodedEmailContent = encodeURIComponent(ref.current.innerHTML.replace(/<br ?\/?>/g, '\n'));

    // Create the mailto link
    var mailtoLink = `mailto:${admin?.virtual_email}?subject=Rent Summary&body=${encodedEmailContent}`;

    // Open the email client with the mailto link
    window.location.href = mailtoLink;
  };

  const actionTypes = [
    'check_property_loan',
    'rent_summary',
    'check_investment_rent_mail',
    'check_settlement_mail',
    'check_deprecation_mail',
    'add_property',
    'check_tax_accountant',
    'tax_accountant_request',
    'mortgage_broker_request',
    'check_audit_trails_category',
    'add_product_request',
  ];

  const groupByActionTypeAndOrder = (data, actionTypes) => {
    const groupedData = [];

    // Grouping logic
    data.forEach((property) => {
      property.action_item
        .filter((item) => !item.complete)
        .forEach((item) => {
          const existingGroup = groupedData.find((group) => group.type === item.type);

          if (item.type === 'check_property_loan') {
            const loanGroupKey = item.loan_id === null ? 'loan_id_null' : 'loan_id_not_null';
            const loanGroup = groupedData.find((group) => group.type === item.type && group.groupKey === loanGroupKey);

            if (!loanGroup) {
              groupedData.push({
                type: item.type,
                message: item.message,
                loan_id: item?.loan_id,
                groupKey: loanGroupKey,
                property: [{ property_name: property.property_name, property_id: item.property_id, transaction_id: item.id }],
              });
            } else {
              loanGroup.property.push({ property_name: property.property_name, property_id: item.property_id, transaction_id: item.id });
            }
          } else {
            if (!existingGroup) {
              groupedData.push({
                type: item.type,
                message: item.message,
                property: [{ property_name: property.property_name, property_id: item.property_id, transaction_id: item.id }],
              });
            } else {
              existingGroup.property.push({ property_name: property.property_name, property_id: item.property_id, transaction_id: item.id });
            }
          }
        });
    });

    // Removing groupKey and ordering based on actionTypes
    return groupedData
      .map(({ groupKey, ...rest }) => rest) // Remove groupKey
      .sort((a, b) => actionTypes.indexOf(a.type) - actionTypes.indexOf(b.type));
  };

  const orderedGroupedData = groupByActionTypeAndOrder(propertyNotificationData, actionTypes);

  return (
    <main className="page-content">
      <div className="container-fluid bg-dash-back">
        <a href="https://wa.me/+61493767832?text=I%20am%20interested%20in%20Property%20Accountant" className="whats_icon" target="_blank" rel="noreferrer">
          <img src={watsappIcon} className="img-fluid" alt="whatsapp icon" />
        </a>
        <div className="layout-specing p-0">
          <div>
            <div className="card_wrapper">
              <div className="row">
                {statItems.map((item, index) => (
                  <div key={index} className="col-xl-3 col-md-6 col-sm-6 col-12 mt-4">
                    <div className="card">
                      <div className="card-body">
                        <img className="circle" src={item.card} alt="" />
                        <h3 className="card-title fw-bold">{item.value}</h3>
                        <div className="card_ico d-flex justify-content-between mt-3 align-items-center">
                          <p className="card-text dashboard-card-text fw-normal m-0">{item.title}</p>
                          <p className={`m-0 ${item.className} `}>
                            {item.antIcon && item.icon}
                            <img src={item.icon} className="img-fluid" alt="" />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <Row className="mb-3 mt-3 card_wrapper property-process-view" gutter={20}>
            <Col xl={24} lg={24} md={24} sm={24} xs={24} className="card_wrapper">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h5 className="m-0">Pending Action / Reminder</h5>
                <Segmented
                  size="large"
                  options={[
                    {
                      label: 'Process View',
                      value: 'process',
                      icon: <SettingOutlined />,
                    },
                    {
                      label: 'Property View',
                      value: 'property',
                      icon: <HomeOutlined />,
                    },
                  ]}
                  value={viewValue}
                  onChange={setViewValue}
                />
              </div>
              <Spin spinning={notificationLoading} size="large">
                {propertyNotificationData && propertyNotificationData.length > 0 && viewValue === 'process' && (
                  <List
                    itemLayout="horizontal"
                    dataSource={orderedGroupedData || []}
                    renderItem={(item, index) => (
                      <List.Item key={index} className="shadow-sm p-4 mb-4 d-block border rounded bg-white">
                        <div className="d-flex justify-content-between align-items-center w-100">
                          <List.Item.Meta avatar={getIcon(item?.type)} title={<h6 className="fw-bold">{getTitle(item?.type, item?.loan_id)}</h6>} description={<b>{item?.message}</b>} />
                          {!item?.complete && item.type !== 'add_product_request' && item.type !== 'check_property_loan' && <div>{renderItem(item)}</div>}
                        </div>
                        <Row className="mt-4" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{ marginLeft: '50px' }}>
                          {item?.property?.map((property_item, index) => (
                            <Col className="gutter-row pb-2" key={index} span={8}>
                              <span>
                                <RightCircleFilled style={{ color: 'grey' }} />{' '}
                                {item.type === 'add_product_request' ? (
                                  <span
                                    onClick={() =>
                                      navigate(`/clients/client/property/${property_item.property_id}?tab=depreciation`, {
                                        state: { fromSameSite: true, transaction_id: property_item.transaction_id },
                                      })
                                    }
                                    className="cursor-pointer text-decoration-underline"
                                  >
                                    {property_item.property_name}
                                  </span>
                                ) : item.type === 'check_property_loan' && item.loan_id === null ? (
                                  <span
                                    onClick={() => navigate(`/clients/client/property/edit/${property_item.property_id}`, { state: { fromSameSite: true } })}
                                    className="cursor-pointer text-decoration-underline"
                                  >
                                    {property_item.property_name}
                                  </span>
                                ) : (
                                  <span>{property_item.property_name}</span>
                                )}
                              </span>
                            </Col>
                          ))}
                        </Row>
                      </List.Item>
                    )}
                  />
                )}
                {propertyNotificationData && propertyNotificationData.length > 0 && viewValue === 'property' && (
                  <List
                    itemLayout="horizontal"
                    dataSource={propertyNotificationData || []}
                    renderItem={(item, index) => (
                      <List.Item className="shadow-sm p-3 mb-4 d-block border rounded bg-white" key={index}>
                        <List.Item.Meta title={<h6 className="fw-bold">{item.property_name}</h6>} />
                        <List
                          itemLayout="horizontal"
                          dataSource={item.action_item?.filter((item) => !item.complete) || []}
                          renderItem={(item, index) => (
                            <List.Item key={index} style={item?.complete ? { background: '#f6f6f6' } : {}}>
                              <List.Item.Meta avatar={getIcon(item?.type)} title={getTitle(item?.type)} description={item?.message} />
                              {!item?.complete && <div>{renderItem(item)}</div>}
                            </List.Item>
                          )}
                        />
                      </List.Item>
                    )}
                  />
                )}
                {!notificationLoading && propertyNotificationData.length < 1 && <NoProperty isTaxAccountant={true} />}
                {notificationLoading && propertyNotificationData.length < 1 && <Card className="vh-100" />}
              </Spin>
            </Col>
          </Row>
        </div>
      </div>

      {/* EMAILNOW MODAL */}
      <Modal onCancel={() => setIsOpenEmail(false)} footer={null} centered width={550} style={{ zIndex: '1005', borderRadius: '1em' }} className="txtAccountentModal" open={isOpenEmail}>
        <div ref={ref}>
          Dear Property Manager, <br />
          <br />
          How are you? I hope you are doing well.
          <br />
          <br />
          Going forward, please add my below email to send my monthly rental summary for all my properties "{isTaxAccountant ? clientVirtualEmail : admin?.virtual_email}"
          <br />
          <br />
          Property Details as below : <br />
          {
            <ul className="mb-0">
              {propertyAddress?.property?.map((item, index) => (
                <li key={index}>{item?.property_name}</li>
              ))}
            </ul>
          }
          {propertyAddress?.property_address && (
            <ul className="mb-0">
              <li>{propertyAddress?.property_address}</li>
            </ul>
          )}
          <br />
          Please confirm by email once this has been action. <br />
          <br />
          Have a nice day <br />
          Regards
        </div>

        <div className="p-2 text-end">
          <Button className="copy_btn3" onClick={() => copyText()}>
            Copy
          </Button>
          <Button className="copy_btn1" onClick={() => emailText()}>
            Send Mail
          </Button>
        </div>
      </Modal>
    </main>
  );
};

export default ClientDashboard;

import { Button, Form, Input, Modal, notification, Popconfirm, Popover, Spin, Table, Upload } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { UploadIcon } from '../../../../assets/Icons/upload';
import { GetTaxUserList } from '../../../../redux/actions/tex-dashboard/UserOnbording/getUserList.action';
import CustomInput from '../../../common';
import { postTaxUserData } from '../../../../redux/actions/tex-dashboard/UserOnbording/postTaxUser.action';
import { QuestionCircleOutlined, SendOutlined } from '@ant-design/icons';
import { postImportTaxUserData } from '../../../../redux/actions/tex-dashboard/UserOnbording/postImportCsv.action';
import deleteIcon from '../../../../assets/images/delete.svg';
import CustomIcon from '../../../sidebar/custIcon';
import { DeleteOnboardingUser } from '../../../../redux/actions/tex-dashboard/UserOnbording/deleteUserList.action';
import { OnboardUserStatus, UserTypes } from '../../../common/userTypes';
import PhoneInput from 'react-phone-input-2';
import { resendEmailChannelPartnerAsync, resendEmailTaxAccountantAsync } from '../../../../redux/reducers/slices/texDashboard/UserOnbording/resendEmailSlice';
import { useNavigate } from 'react-router';

const UserOnbording = () => {
  const [submitType, setSubmitType] = useState('invite_now');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [fileList, setFileList] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [fileData, setFileData] = useState([]);
  const [data, setData] = useState([]);
  const [userform] = Form.useForm();
  const [ImportData] = Form.useForm();

  const inviteNowText = (
    <ul className="fs-6">
      <li>By inviting your client now, both an email and SMS with your referral code will be sent. Once your client signs up and grants access, you’ll be able to view their details.</li>
      <li>If you prefer to manage your client’s profile first, you can click "Invite Later" and send the invitation once their profile is set up.</li>
    </ul>
  );

  const inviteLaterText = (
    <div className="fs-6">
      <p className="ps-3">
        If you prefer to create and manage your client's profile yourself, you can choose the "Invite Later" option.
        <p>
          By selecting this, you can first set up the property profile using existing information, and update details like the depreciation schedule or settlement statement. Once you're ready, you can
          invite your client to complete the process, which will involve:
        </p>
      </p>
      <ul className="ps-5">
        <li>Emailing the Rent Statement</li>
        <li>Connecting the Bank Loan Data Feed</li>
        <li>Adding Expenses (e.g., land tax, insurance) with just a few clicks.</li>
      </ul>
      <p className="ps-3">
        This option streamlines the process for your client. Additionally, if you prefer to manage the profile on their behalf indefinitely, you can continue using this option without sending an
        invite.
      </p>
    </div>
  );

  const userType = useSelector((state) => state.getNotificationDataReducer)?.data?.result?.userData?.user_type;
  const isTaxAccountant = [UserTypes.TAXACCOUTANT, UserTypes.SUB_TAXACCOUTANT, UserTypes.ADMIN_SUB_TAXACCOUNTANT].includes(userType);

  const onChange = (info) => {
    if (info) {
      setFileList(info?.file?.originFileObj);
    } else {
      setFileList([]);
    }
  };

  const onRemove = () => {
    try {
      setFileList([]);
      // setAllData([])
    } catch (error) {
      console.error(error);
    }
  };

  const ImportFinish = (event) => {
    setLoading1(true);
    let formData = new FormData();
    formData.append('file', fileList);
    isTaxAccountant && formData.append('invite_now', submitType === 'invite_now');
    if (event.file === undefined && fileList?.length < 1) {
      setLoading1(false);
      notification.error({
        message: 'Failure',
        description: 'Please Select CSV!',
      });
    } else {
      dispatch(postImportTaxUserData(formData)).then((res) => {
        if (res.payload.success === true) {
          setFileData(res.payload.result.result);
          setIsModalOpen1(true);
          dispatch(GetTaxUserList());
          setIsModalOpen(false);
          setLoading1(false);
          ImportData.resetFields();
          if (submitType !== 'invite_now') {
            navigate('/dashboard', { state: { invite_later: true } });
          }
        } else {
          notification.error({
            message: 'Failure',
            description: res.payload.message || 'Please Select correct CSV!',
          });
          setLoading1(false);
        }
      });
    }
  };

  const handleCancel = () => {
    userform.resetFields();
    setFileList([]);
    setIsModalOpen(false);
  };
  const handleCancel1 = () => {
    // userform.resetFields()
    setIsModalOpen1(false);
  };

  // LIST DATA
  const taxUserData = useSelector((state) => state.getTaxUserListReducer);

  useEffect(() => {
    dispatch(GetTaxUserList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      setSubmitType('invite_now');
    };
  }, []);

  // //* API Call
  useEffect(() => {
    setLoading(taxUserData.isLoading);
    if (taxUserData && taxUserData.data.result && taxUserData.error === false) {
      let dt = taxUserData.data.result.map((elm) => {
        return { ...elm, key: crypto.randomUUID() };
      });
      setData(dt);
    }
  }, [taxUserData]);

  const onFinish = (values) => {
    const params = {
      ...values,
      mobile: `+${values.mobile}`,
    };
    if (isTaxAccountant) {
      params['invite_now'] = submitType === 'invite_now';
    }
    setLoading1(true);
    dispatch(postTaxUserData(params)).then((res) => {
      if (res.payload.success === true) {
        notification.success({
          message: 'Success',
          description: res?.payload.message,
        });
        if (params?.invite_now === false) {
          navigate('/dashboard', { state: { invite_later: true } });
        }
        dispatch(GetTaxUserList());
        setIsModalOpen(false);
        setLoading1(false);
        userform.resetFields();
      } else {
        notification.error({
          message: 'Failure',
          description: res?.payload.message || 'Something went wrong!',
        });
        setLoading1(false);
      }
    });
  };

  const addClients = () => {
    setIsModalOpen(true);
  };
  const deleteOnbording = (id) => {
    setLoading(true);
    dispatch(DeleteOnboardingUser(id)).then((res) => {
      if (res.payload.success === true) {
        dispatch(GetTaxUserList());
        notification.success({
          message: 'Success',
          description: res.payload.message || 'Property Deleted Successfully',
        });
        setLoading(false);
      } else {
        notification.error({
          message: 'Failure',
          description: res?.payload.message || 'Something went wrong!',
        });
        setLoading(false);
      }
    });
  };

  const handleSendEmail = async (record) => {
    setLoading(true);
    const { id } = record;
    let res;
    if (isTaxAccountant) {
      res = await dispatch(resendEmailTaxAccountantAsync([id]));
    }
    if (userType === UserTypes.CHANNEL_PARTNER) {
      res = await dispatch(resendEmailChannelPartnerAsync(id));
    }
    if (res?.payload?.data?.success === true) {
      notification.success({
        message: 'Success',
        description: res?.payload?.data?.message,
      });
    } else {
      notification.error({
        message: 'Failure',
        description: res?.payload?.data?.message || 'Something went wrong!',
      });
    }
    setLoading(false);
  };

  // SORTING TABLE

  // TABLE DATA
  const columns = [
    {
      title: 'Username',
      dataIndex: 'name',
      key: 'username',
      width: '22%',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: '22%',
    },
    {
      title: 'Phone',
      dataIndex: 'phone_number',
      key: 'phone_number',
      width: '13%',
    },
    {
      title: 'Status',
      dataIndex: 'user_status',
      key: 'user_status',
      width: '10%',
      sorter: (a, b) => a.user_status - b.user_status,
      render: (text, record) => {
        return record?.user_status === OnboardUserStatus.APPROVED
          ? `Approved`
          : record?.user_status === OnboardUserStatus.PENDING
          ? `Pending`
          : record?.user_status === OnboardUserStatus.CENCELED
          ? `Canceled`
          : record?.user_status === OnboardUserStatus.FAILED
          ? `Failed`
          : record?.user_status === OnboardUserStatus.ALREADY_EXISTS
          ? `Already Exist`
          : '-';
      },
    },
    {
      title: 'Action',
      dataIndex: 'status',
      key: 'status',
      width: '2%',
      render: (text, record) => {
        return (
          <>
            {record.taxacc_status === 1 ? (
              ''
            ) : (
              <div className=" d-flex justify-content-between align-items-center Bank_card">
                {userType !== UserTypes.SUB_TAXACCOUTANT && (
                  <Popconfirm placement="topRight" title="Are you sure to delete?" onConfirm={() => deleteOnbording(record.id)} okText="Yes" cancelText="No">
                    <Button type="primary" className="Delete_btn" icon={<CustomIcon icon={deleteIcon} />} size="small" />
                  </Popconfirm>
                )}
                {record?.user_status === OnboardUserStatus.PENDING && (
                  <Button className="Connect_btn " icon={<SendOutlined rotate={-45} />} onClick={() => handleSendEmail(record)}>
                    Resend
                  </Button>
                )}
              </div>
            )}
          </>
        );
      },
    },
  ];

  const successFinish = () => {
    setIsModalOpen1(false);
    window.location.reload();
  };

  return (
    <>
      <main className="page-content">
        <div className="container-fluid bg-dash-back">
          <div className="layout-specing">
            <Spin spinning={loading} size="large">
              <div className="row align-items-center mt-1">
                <div className="col-xl-6 col-md-6 col-sm-6 col-12">
                  <div className="text-center text-sm-start mb-sm-0 mb-3 mb-sm-0">
                    <h5 className="m-0">Invite Clients List</h5>
                  </div>
                </div>
                <div className="col-xl-6 col-md-6 col-sm-6 col-12">
                  <div className="text-center text-sm-end justify-content-between">
                    <Button className="property_btn1" onClick={() => addClients()}>
                      Add Clients
                    </Button>
                  </div>
                </div>
              </div>
              <div className="table">
                <Table paddingclassName="mt-4 property_list" columns={columns} dataSource={data} rowCount={10} />
              </div>
            </Spin>
          </div>
        </div>
      </main>
      {/* FORM MODAL  */}
      <Modal title={<h4 className="mb-0">Add Clients</h4>} open={isModalOpen} width={600} onCancel={handleCancel} footer={null} destroyOnClose>
        <Spin spinning={loading1}>
          <div className="add_form">
            <div className="row justify-content-center">
              <Form form={userform} name="userform" onFinish={onFinish} autoComplete="off" layout="vertical">
                <div className="card-body p-3 pb-0">
                  <div className="row">
                    <div className="col-md-6">
                      <Form.Item
                        label="Name"
                        name="name"
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: 'Please input your name!',
                          },
                        ]}
                      >
                        <CustomInput className="form-control p-2" inputtype="alphabetspace" />
                      </Form.Item>
                    </div>
                    <div className="col-md-6">
                      <Form.Item
                        label="Phone"
                        name="mobile"
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: 'Please input correct phone number!',
                          },
                        ]}
                        className="phone_input"
                      >
                        <PhoneInput country="au" placeholder="Enter Phone Number" enableSearch />
                      </Form.Item>
                    </div>
                    <div className="col-md-12">
                      <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: 'Please input your valid email!',
                            type: 'email',
                          },
                        ]}
                      >
                        <Input type="email" autoComplete="off" className="form-control p-2" />
                      </Form.Item>
                    </div>
                    {isTaxAccountant && (
                      <div className="col-md-12 text-center">
                        <Form.Item>
                          <Button
                            type="primary"
                            className=""
                            htmlType="submit"
                            onClick={() => setSubmitType('invite_now')}
                            icon={
                              <Popover placement="bottom" content={inviteNowText} trigger="hover">
                                <QuestionCircleOutlined className="cursor-pointer pt-1 " />
                              </Popover>
                            }
                            iconPosition="end"
                          >
                            Invite Now
                          </Button>
                          <Button
                            icon={
                              <Popover placement="bottom" content={inviteLaterText} trigger="hover">
                                <QuestionCircleOutlined className="cursor-pointer pt-1 " />
                              </Popover>
                            }
                            iconPosition="end"
                            className="ml-2"
                            type="primary"
                            htmlType="submit"
                            onClick={() => setSubmitType('invite_later')}
                          >
                            Invite Later
                          </Button>
                        </Form.Item>
                      </div>
                    )}
                  </div>
                  {!isTaxAccountant && (
                    <Form.Item className="text-center">
                      <Button type="primary" className="mt-1" htmlType="submit">
                        Submit
                      </Button>
                    </Form.Item>
                  )}
                </div>
              </Form>
            </div>
          </div>

          <div className="site_header mt-0 text-center">
            <label className="mb-2">
              <h5>or</h5>
            </label>
          </div>
          <Form style={{ maxWidth: 600 }} onFinish={ImportFinish} form={ImportData}>
            <div className="record_file User_import">
              <Form.Item className="mb-2 text-start text-muted">
                <p className="mb-0">
                  Download a
                  <a href="https://tpa-prod-assets-bucket.s3.ap-southeast-2.amazonaws.com/sample.csv" className="sample_btn">
                    {' '}
                    sample.csv{' '}
                  </a>
                  file and compare it with your import file to ensure that the file is ready to import.
                </p>
              </Form.Item>
              <Form.Item name="file">
                <div className="file-input ImportUser">
                  <Upload onChange={onChange} onRemove={onRemove} accept=".csv" maxCount={1} type="file">
                    {fileList.length >= 1 ? null : (
                      <div>
                        <UploadIcon />
                        <div
                          style={{
                            marginTop: 8,
                          }}
                          className="ant-select-file"
                        >
                          Select File
                        </div>
                      </div>
                    )}
                  </Upload>
                </div>
              </Form.Item>
            </div>
            {isTaxAccountant && (
              <div className="col-md-12 text-center">
                <Form.Item>
                  <Button
                    type="primary"
                    className=""
                    htmlType="submit"
                    onClick={() => setSubmitType('invite_now')}
                    icon={
                      <Popover placement="bottom" content={inviteNowText} trigger="hover">
                        <QuestionCircleOutlined className="cursor-pointer pt-1 " />
                      </Popover>
                    }
                    iconPosition="end"
                  >
                    Invite Now
                  </Button>
                  <Button
                    icon={
                      <Popover placement="bottom" content={inviteLaterText} trigger="hover">
                        <QuestionCircleOutlined className="cursor-pointer pt-1 " />
                      </Popover>
                    }
                    iconPosition="end"
                    className="ml-2"
                    type="primary"
                    htmlType="submit"
                    onClick={() => setSubmitType('invite_later')}
                  >
                    Invite Later
                  </Button>
                </Form.Item>
              </div>
            )}
            {!isTaxAccountant && (
              <Form.Item className="text-center">
                <Button type="primary" htmlType="submit">
                  Import
                </Button>
              </Form.Item>
            )}
          </Form>
        </Spin>
      </Modal>

      {/* IMPORT DATA TABALE MODAL  */}
      <Modal
        title={
          <h4 className="mb-0">
            <p style={{ color: 'green', fontSize: '22px', marginBottom: 0 }}>Success!</p>
          </h4>
        }
        open={isModalOpen1}
        width={500}
        onCancel={handleCancel1}
        footer={null}
      >
        <div className="d-flex">
          <div className="me-5">
            <p style={{ fontSize: '16px' }}>
              <span style={{ color: 'gray', fontWeight: 900, fontSize: '18px' }}>{fileData?.total_user}</span> clients has been imported successfully.
            </p>
            <p style={{ fontSize: '16px' }}> We'll automatically notify clients via Email and SMS.</p>
            <p style={{ fontSize: '16px' }}>
              {' '}
              Here are the few things you can check,
              <ul>
                <li>Status of each on boarded Client </li>
                <li>Details of your client, Once approved </li>
              </ul>
            </p>
            <p style={{ fontSize: '16px' }}> Thank you!</p>
          </div>
        </div>
        <div className="text-end mt-2">
          <Button type="primary" onClick={() => successFinish()} className="mt-1" htmlType="submit">
            Ok
          </Button>
        </div>
        {/* <Table dataSource={fileData.failed_arr} bordered columns={FailedUsercolumns} sticky pagination={false} className="Failed_Data_Table" /> */}
      </Modal>
    </>
  );
};

export default UserOnbording;

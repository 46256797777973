import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, DatePicker, Empty, Form, Input, InputNumber, notification, Select, Spin } from 'antd';

import {
  getDepreciationDataAsync,
  getDepreciationListDataAsync,
  getDepreciationProductListAsync,
  notApplicableDepreciationAsync,
  saveDepreciationDataAsync,
  selectDepreciationData,
  selectDepreciationLoading,
  selectDepreciationProductList,
} from '../../../redux/reducers/slices/Depreciation/DepreciationSlice';
import { CloseCircleOutlined, PlusOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { DepreciationMethod, DepreciationStatus, TrensectionType } from '../../common/userTypes';
import { removeDepreciationItem } from '../../../redux/reducers/slices/Header/getNotificationData.slice';
import { getAuditTrailDepreciationAsync } from '../../../redux/reducers/slices/AuditTrail/AuditTrailDepreciationSlice';
import { propertyRemoveDepreciationItem } from '../../../redux/reducers/slices/property/getPropertyListById.slice';

const AddDepreciation = ({ record = null, setDrawerOpen = () => {}, onUpdateDepreciation = () => {}, filters = null, category_id = null, fromAuditTrail = false, auditYear = null }) => {
  const [previewUrl, setPreviewUrl] = useState('');
  const [productList, setProductList] = useState([]);

  const dispatch = useDispatch();
  const mainProductList = useSelector(selectDepreciationProductList);
  const depreciationData = useSelector(selectDepreciationData);
  const [form] = Form.useForm();
  const depreciationLoading = useSelector(selectDepreciationLoading);
  const [loading, setLoading] = useState(false);

  const currentClient = localStorage.getItem('currentClient');

  useEffect(() => {
    const tempProductList = mainProductList?.map((item) => ({
      label: item.asset,
      value: item.id,
      effectiveLife: item.asset_life_year,
      key: item.id,
    }));
    setProductList(tempProductList);
  }, [mainProductList]);

  useEffect(() => {
    dispatch(
      getDepreciationDataAsync({
        id: record?.id,
        type: record?.transaction_type,
        user_id: currentClient,
      }),
    );
  }, [dispatch, record, currentClient]);

  useEffect(() => {
    setPreviewUrl(depreciationData?.receipt_path);
  }, [depreciationData]);

  useEffect(() => {
    form.setFieldsValue({
      transaction_date: dayjs(depreciationData?.transaction_date),
      total_amount: Number(depreciationData?.amount)?.toFixed(2),
      depreciation_method: DepreciationMethod.WDV_DEPRECIATION,
    });
    if (record?.category_name !== 'Capital Improvement') {
      form.setFieldsValue({
        rental_property_items: [{ product_id: null, product_price: null, effectiveLife: null }],
      });
    }
  }, [depreciationData, form, record]);

  const isPdfUrl = (url) => {
    const regex = /https?:\/\/[^\s]*\.pdf/g;
    return url?.match(regex) || url?.includes('application/pdf');
  };

  useEffect(() => {
    dispatch(getDepreciationProductListAsync());
  }, [dispatch]);

  const depreciationOptions = [
    {
      value: DepreciationMethod.PRIME_COST_DEPRECIATION,
      label: 'Prime Cost',
    },
    {
      value: DepreciationMethod.WDV_DEPRECIATION,
      label: 'Diminishing Value',
    },
  ];

  const handleFinish = async (values) => {
    let filteredValues = {};
    if (record?.category_name === 'Capital Improvement') {
      filteredValues = {
        ...values,
        user_id: currentClient,
        transaction_id: record?.id,
        transaction_date: dayjs(values.transaction_date).format('YYYY-MM-DD'),
        rental_property_items: [{ product_id: null, product_price: values.total_amount }],
      };
    } else {
      filteredValues = {
        ...values,
        user_id: currentClient,
        transaction_id: record?.id,
        rental_property_items: values?.rental_property_items?.map?.(({ effectiveLife, ...rest }) => rest),
        transaction_date: dayjs(values.transaction_date).format('YYYY-MM-DD'),
      };
    }

    delete values.total_amount;
    delete filteredValues.total_amount;

    const totalProductCost = Number(Number(values?.rental_property_items?.reduce((accumulator, currentProduct) => accumulator + currentProduct.product_price, 0))?.toFixed(2));

    if (totalProductCost < Number(Number(depreciationData?.amount)?.toFixed(2))) {
      notification.error({
        message: 'Error',
        description: 'Total product cost should not be less than total amount!',
      });
      return;
    }
    if (totalProductCost > Number(Number(depreciationData?.amount)?.toFixed(2))) {
      notification.error({
        message: 'Error',
        description: 'Total product cost should not be greater than the total amount!',
      });
      return;
    }

    try {
      const res = await dispatch(saveDepreciationDataAsync(filteredValues));
      if (res?.payload?.data?.success) {
        await dispatch(removeDepreciationItem(record?.id));
        await dispatch(propertyRemoveDepreciationItem(record?.id));
        notification.success({
          message: 'Success',
          description: 'Depreciation successfully generated for the added details.',
        });
        setPreviewUrl('');
        onUpdateDepreciation();
        form.resetFields();
        setDrawerOpen(false);
        if (fromAuditTrail) {
          await dispatch(getAuditTrailDepreciationAsync({ ...filters, year: auditYear }));
        } else {
          await dispatch(getDepreciationListDataAsync({ ...filters, user_id: currentClient, category_id }));
        }
      } else {
        notification.error({
          message: 'Failure',
          description: res?.payload?.data?.message || 'Something went wrong!',
        });
      }
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error.message || 'Something went wrong!',
      });
    }
  };

  const handleNotApplicable = async () => {
    try {
      setLoading(true);
      const res = await dispatch(notApplicableDepreciationAsync({ id: record?.id, currentClient }));
      if (res?.payload?.data?.success) {
        setDrawerOpen(false);
        dispatch(removeDepreciationItem(record?.id));
        notification.success({
          message: 'Success',
          description: 'Depreciation marked as not applicable',
        });
        setPreviewUrl('');
        form.resetFields();
        setLoading(false);

        if (fromAuditTrail) {
          dispatch(getAuditTrailDepreciationAsync({ ...filters, year: auditYear }));
        } else {
          dispatch(getDepreciationListDataAsync({ ...filters, user_id: currentClient, category_id }));
        }
      } else {
        notification.error({
          message: 'Failure',
          description: res?.payload?.data?.message || 'Something went wrong!',
        });
        setLoading(false);
      }
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error.message || 'Something went wrong!',
      });
      setLoading(false);
    }
  };

  return (
    <Spin spinning={depreciationLoading || loading} size="large">
      <main className="page-content">
        <div className="container-fluid">
          <div className="layout-specing">
            <div className="record_wrapper">
              <Form name="addProductForm" form={form} layout="vertical" autoComplete="off" onFinish={handleFinish} wrapperCol={{ span: 24 }}>
                <div className="row">
                  <div className="col-xl-6 col-md-6 col-12 mt-3">
                    <div className="record_upload" style={{ width: '100%' }}>
                      <div className="card d-flex justify-content-center align-items-center p-3" style={{ width: '100%' }}>
                        <div className="record_file" style={{ width: '100%' }}>
                          {previewUrl ? (
                            <div id="Iframe-Master-CC-and-Rs" className="set-margin set-padding set-border set-box-shadow center-block-horiz">
                              <div className="responsive-wrapper responsive-wrapper-wxh-572x612 text-center">
                                {!isPdfUrl(previewUrl) ? <img src={previewUrl} className="Image_set h-100 img-fluid" alt="Preview Url" /> : <iframe title="nothing" src={previewUrl}></iframe>}
                              </div>
                            </div>
                          ) : (
                            <div>
                              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No receipt uploaded" />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-6 col-md-6 col-12 mt-3">
                    <div className="card overflow-y-auto new-scroll">
                      <div className="card-body">
                        <div className="row">
                          <div className="row">
                            <div className="col-xl-6 col-md-6 col-12">
                              <Form.Item label="Purchase Date" name={'transaction_date'} rules={[{ required: true, message: 'Please select date' }]}>
                                <DatePicker disabled={record?.transaction_type !== TrensectionType.MANUAL_TYPE_TRANSACTION} format={'DD-MM-YYYY'} placeholder="Purchase Date" className="w-100" />
                              </Form.Item>
                            </div>
                            <div className="col-xl-6 col-md-6 col-12">
                              <Form.Item label="Total Amount" name={'total_amount'}>
                                <Input placeholder="Total Amount" className="w-100" disabled size="large" />
                              </Form.Item>
                            </div>
                            <div className="col-12">
                              <Form.Item label="Depreciation Method" name={'depreciation_method'} rules={[{ required: true, message: 'Please select method' }]}>
                                <Select placeholder="Depreciation Method" className="w-100" options={depreciationOptions} />
                              </Form.Item>
                            </div>
                            <Form.List name="rental_property_items">
                              {(fields, { add, remove }) => (
                                <>
                                  {fields.map(({ key, name, ...restField }) => (
                                    <div key={key} className="mb-4 w-100 border border-1 pt-4">
                                      <div className="row">
                                        <div className="col-xl-12 col-md-12 col-12 position-relative">
                                          <Form.Item label="Product Name" {...restField} name={[name, 'product_id']} rules={[{ required: true, message: 'Please select a product' }]}>
                                            <Select
                                              showSearch
                                              optionFilterProp="children"
                                              placeholder="Select Product"
                                              className="w-100"
                                              filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
                                              options={productList}
                                              onChange={(value) => {
                                                form.setFields([
                                                  {
                                                    name: ['rental_property_items', name, 'effectiveLife'],
                                                    errors: [],
                                                  },
                                                ]);
                                                const selectedProduct = productList.find((product) => product.value === value);
                                                const selectedLife = selectedProduct?.effectiveLife || 0;
                                                form.setFieldValue(['rental_property_items', name, 'effectiveLife'], selectedLife);
                                              }}
                                            />
                                          </Form.Item>
                                        </div>
                                        {fields.length > 1 && (
                                          <div className="product-delete-button">
                                            <CloseCircleOutlined style={{ fontSize: '1.2rem', color: 'red' }} onClick={() => remove(name)} />
                                          </div>
                                        )}
                                        <div className="col-xl-6 col-md-6 col-12">
                                          <Form.Item label="Purchase Cost" {...restField} name={[name, 'product_price']} rules={[{ required: true, message: 'Please input cost' }]}>
                                            <InputNumber placeholder="Cost" prefix="$" className="w-100" min={0} size="large" />
                                          </Form.Item>
                                        </div>
                                        <div className="col-xl-6 col-md-6 col-12">
                                          <Form.Item label="Effective Life (Years)" {...restField} name={[name, 'effectiveLife']} rules={[{ required: true, message: 'Please select Product' }]}>
                                            <InputNumber placeholder="Effective Life (Years)" min={0} className="w-100" readOnly size="large" />
                                          </Form.Item>
                                        </div>
                                      </div>
                                    </div>
                                  ))}

                                  {record?.category_name !== 'Capital Improvement' && (
                                    <Form.Item>
                                      <Button
                                        type="primary"
                                        onClick={() => {
                                          add();
                                        }}
                                        block
                                        icon={<PlusOutlined />}
                                      >
                                        Add Product
                                      </Button>
                                    </Form.Item>
                                  )}
                                </>
                              )}
                            </Form.List>

                            <div className={`d-flex mt-2 ${record?.depreciation_status === DepreciationStatus.DEPRECIATION_NOT_APPLICABLE ? 'justify-content-end' : 'justify-content-between'}`}>
                              {record?.depreciation_status !== DepreciationStatus.DEPRECIATION_NOT_APPLICABLE && (
                                <Button size="large" className="Delete_btn text-white" onClick={() => handleNotApplicable()}>
                                  Not Applicable
                                </Button>
                              )}
                              <div>
                                <Button size="large" type="primary" onClick={() => form.submit()}>
                                  Submit
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </main>
    </Spin>
  );
};

export default AddDepreciation;

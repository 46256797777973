import React, { useEffect, useRef, useState, forwardRef } from 'react';
import '../../../assets/less/index.less';

import card1 from '../../../assets/card1.png';
import card2 from '../../../assets/card2.png';
import card3 from '../../../assets/card3.png';
import card4 from '../../../assets/card4.png';

import iconDolor from '../../../assets/dashboard-cosmetics/dolor.svg';
import iconGroup from '../../../assets/dashboard-cosmetics/Group.png';
import iconLoan from '../../../assets/dashboard-cosmetics/loan.svg';
import iconPie from '../../../assets/dashboard-cosmetics/paichart1.svg';
import watsappIcon from '../../../assets/images/watsappIcon.svg';

import { GetDashboardData, GetPropertyNotificationData } from '../../../redux/actions/dashboard/getDashboardData.action';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Spin, notification, Button, Row, Col, List, Result, Popconfirm, Segmented, Card, Statistic } from 'antd';
import { HomeOutlined, RightCircleFilled, SettingOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { LoanReject } from '../../../redux/actions/dashboard/loanReject.action';
import { getTitle, getIcon } from '../../common/NotificationUtil';
import NoProperty from '../../common/NoProperty';
import Consent from '../BankConnection/consent';
import { ProductTourType } from '../../common/userTypes';
import { formatCurrency } from '../../../utils';
import HowWorks from './HowWorks';
import { notApplicableDepreciationAsync } from '../../../redux/reducers/slices/Depreciation/DepreciationSlice';
import { removeDepreciationItem } from '../../../redux/reducers/slices/Header/getNotificationData.slice';

const Home = forwardRef(function Home(_props, tour_ref) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const ref = useRef();
  const [loading, setLoading] = useState(false);
  const [notificationLoading, setNotificationLoading] = useState(false);
  const [isOpenEmail, setIsOpenEmail] = useState(false);
  const [dData, setDData] = useState([]);
  const [propertyAddress, setPropertyAddress] = useState({});
  const [propertyNotificationData, setPropertyNotificationData] = useState([]);
  const currentClient = localStorage.getItem('currentClient');
  const [viewValue, setViewValue] = useState('process');

  const [loanOverview, setLoanOverview] = useState({
    name: 'All',
    available_redraw: 0,
    emi: 0,
    interest_rate: 0,
  });
  const dashboardDta = useSelector((state) => state.getDashboardDataReducer);
  const propertyNotificationDta = useSelector((state) => state.getPropertyNotificationDataReducer);
  const notificationDta = useSelector((state) => state.getNotificationDataReducer);
  const admin = JSON.parse(localStorage.getItem('admin'));

  const newNet = dData.netIncome;
  const newMarket = dData.marketValue;
  const newLoan = dData.loanBalance;
  const newEquity = dData.netEquity;

  // Initial api call
  useEffect(() => {
    dispatch(GetDashboardData());
    dispatch(GetPropertyNotificationData());
  }, [dispatch]);

  // Set dashboard stastics reducer data to state
  useEffect(() => {
    setLoading(dashboardDta.isLoading || false);
    if (dashboardDta && dashboardDta.data.result && dashboardDta.error === false) {
      setDData(dashboardDta.data.result);
      setLoanOverview({ name: 'All', ...dashboardDta.data.result?.loan_review?.all });
    }
  }, [dashboardDta]);

  // Set property notification stastics reducer data to state
  useEffect(() => {
    setNotificationLoading(propertyNotificationDta.isLoading || false);
    if (propertyNotificationDta && propertyNotificationDta.data.result && propertyNotificationDta.error === false) {
      setPropertyNotificationData(propertyNotificationDta.data.result);
    }
  }, [propertyNotificationDta]);

  const actionItemProperty = dData?.ActionItemData?.filter((item) => item.type === 'add_property') || [];

  const NOLoan = (id) => {
    dispatch(LoanReject(id)).then((res) => {
      if (res.payload.success === true) {
        notification.success({
          message: 'Success',
          description: res.payload.message,
        });
      }
      dispatch(GetPropertyNotificationData());
    });
  };

  const handleNotApplicable = async (id) => {
    try {
      const res = await dispatch(notApplicableDepreciationAsync({ id, currentClient }));
      if (res?.payload?.data?.success) {
        notification.success({
          message: 'Success',
          description: res?.payload?.data?.message || 'Depreciation marked as not applicable!',
        });
        await dispatch(removeDepreciationItem(id));
        await dispatch(GetPropertyNotificationData());
      } else {
        notification.error({
          message: 'Failure',
          description: res?.payload?.data?.message || 'Something went wrong!',
        });
      }
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error.message || 'Something went wrong!',
      });
    }
  };

  const renderItem = (item) => {
    if (item.type === 'check_property_loan') {
      return (
        <div key={crypto.randomUUID()} className="my-3 my-sm-0">
          {!item.loan_id ? (
            <>
              <Button className="mr-2" onClick={() => navigate(viewValue === 'process' ? '/property' : `/property/edit/${item.property_id}`, { state: { fromSameSite: true } })}>
                Update
              </Button>
              {viewValue !== 'process' && <Button onClick={() => NOLoan(item.property_id)}>Loan Not Applicable</Button>}
            </>
          ) : (
            <Button className="email_btn" onClick={() => navigate(`/bank`, { state: { fromSameSite: true } })}>
              Connect
            </Button>
          )}
        </div>
      );
    } else if (item.type === 'check_deprecation_mail') {
      return (
        <div key={crypto.randomUUID()} className="my-3 my-sm-0">
          {/* {item.property_id ? <Button onClick={() => RemindDepreciation(item.property_id)}>Remind me later</Button> : ''} */}
        </div>
      );
    } else if (item.type === 'check_settlement_mail') {
      return (
        <div key={crypto.randomUUID()} className="my-3 my-sm-0">
          {/* {item.property_id ? <Button onClick={() => RemindSettlement(item.property_id)}>Remind me later</Button> : ''} */}
        </div>
      );
    } else if (item.type === 'check_investment_rent_mail') {
      return (
        <div key={crypto.randomUUID()} className="my-3 my-sm-0">
          {item.type ? (
            <Button
              className="email_btn"
              onClick={() => {
                setIsOpenEmail(true);
                setPropertyAddress(item);
              }}
            >
              Email Now
            </Button>
          ) : (
            ''
          )}
        </div>
      );
    } else if (item.type === 'add_property') {
      return (
        <div key={crypto.randomUUID()} className="my-3 my-sm-0">
          <Button onClick={() => navigate(`/property/add`, { state: { fromSameSite: true } })}>Add</Button>
        </div>
      );
    } else if (item.type === 'check_audit_trails_category') {
      return (
        <div key={crypto.randomUUID()} className="my-3 my-sm-0">
          <Button onClick={() => navigate(`/audit-trail`, { state: { fromSameSite: true } })}>Review Now</Button>
        </div>
      );
    } else if (item.type === 'add_product_request') {
      return (
        <>
          <Button
            className="mr-2"
            onClick={() => {
              navigate(`/property/${item.property_id}?tab=depreciation`, {
                state: { fromSameSite: true, transaction_id: item.id },
              });
            }}
          >
            Yes
          </Button>
          <Popconfirm title="Are you sure!" onConfirm={() => handleNotApplicable(item.id)} okText="Yes, Sure" cancelText="No">
            <Button onClick={(e) => e.stopPropagation()}>No</Button>
          </Popconfirm>
        </>
      );
    } else {
      return '';
    }
  };

  const handlechange = () => {
    if (actionItemProperty.length > 0) {
      notification.error({
        message: 'Failure',
        description: 'Add property first!',
        duration: 10,
      });
    } else {
      navigate('/record-expenses', { state: { fromSameSite: true } });
    }
  };

  const handleAddProperty = (e) => {
    if (notificationDta?.data?.result?.userData?.product_tour === ProductTourType.PRODUCT_TOUR_PENDING) {
      e.preventDefault();
    } else {
      navigate('/property/add', { state: { fromSameSite: true } });
    }
  };

  const copyText = () => {
    navigator.clipboard.writeText(ref.current.innerHTML.replace(/<br ?\/?>/g, '\n'));

    notification.success({
      message: 'Success',
      description: 'Text copied.',
    });
  };

  const emailText = () => {
    var encodedEmailContent = encodeURIComponent(ref.current.innerHTML.replace(/<br ?\/?>/g, '\n'));

    // Create the mailto link
    var mailtoLink = `mailto:${admin?.virtual_email}?subject=Rent Summary&body=${encodedEmailContent}`;

    // Open the email client with the mailto link
    window.location.href = mailtoLink;
  };

  // Extract unique action types for Process View
  const actionTypes = [
    'check_property_loan',
    'rent_summary',
    'check_investment_rent_mail',
    'check_settlement_mail',
    'check_deprecation_mail',
    'add_property',
    'check_tax_accountant',
    'tax_accountant_request',
    'mortgage_broker_request',
    'check_audit_trails_category',
    'add_product_request',
  ];

  const groupByActionTypeAndOrder = (data, actionTypes) => {
    const groupedData = [];

    // Grouping logic
    data.forEach((property) => {
      property.action_item
        .filter((item) => !item.complete)
        .forEach((item) => {
          const existingGroup = groupedData.find((group) => group.type === item.type);

          if (item.type === 'check_property_loan') {
            const loanGroupKey = item.loan_id === null ? 'loan_id_null' : 'loan_id_not_null';
            const loanGroup = groupedData.find((group) => group.type === item.type && group.groupKey === loanGroupKey);

            if (!loanGroup) {
              groupedData.push({
                type: item.type,
                message: item.message,
                loan_id: item?.loan_id,
                groupKey: loanGroupKey,
                property: [{ property_name: property.property_name, property_id: item.property_id, transaction_id: item.id }],
              });
            } else {
              loanGroup.property.push({ property_name: property.property_name, property_id: item.property_id, transaction_id: item.id });
            }
          } else {
            if (!existingGroup) {
              groupedData.push({
                type: item.type,
                message: item.message,
                property: [{ property_name: property.property_name, property_id: item.property_id, transaction_id: item.id }],
              });
            } else {
              existingGroup.property.push({ property_name: property.property_name, property_id: item.property_id, transaction_id: item.id });
            }
          }
        });
    });

    // Removing groupKey and ordering based on actionTypes
    return groupedData
      .map(({ groupKey, ...rest }) => rest) // Remove groupKey
      .sort((a, b) => actionTypes.indexOf(a.type) - actionTypes.indexOf(b.type));
  };

  const orderedGroupedData = groupByActionTypeAndOrder(propertyNotificationData, actionTypes);

  return (
    <main className="page-content ">
      <div className="container-fluid bg-dash-back">
        <a
          href="https://wa.me/+61493767832?text=I%20am%20interested%20in%20Property%20Accountant"
          className="whats_icon"
          target="_blank"
          rel="noreferrer"
          ref={(ref) => (tour_ref.current.home_whatsup_btn = ref)}
        >
          <img src={watsappIcon} className="img-fluid" alt="whatsapp icon" />
        </a>
        <div className="layout-specing">
          <Spin spinning={loading} size="large">
            <div className="row align-items-center mt-1">
              <div className="col-xl-6 col-md-6 col-sm-6 col-12">
                <div className="text-center text-sm-start mb-sm-0 mb-3 mb-sm-0">
                  <h5 className="m-0">Properties Dashboard</h5>
                </div>
              </div>
              <div className="col-xl-6 col-md-6 col-sm-6 col-12">
                <div className="text-center text-sm-end justify-content-between">
                  <Button onClick={(e) => handleAddProperty(e)} className="property_btn" ref={(ref) => (tour_ref.current.home_property_btn = ref)}>
                    + Add Property
                  </Button>

                  <Button onClick={() => handlechange()} className="property_btn1" ref={(ref) => (tour_ref.current.home_property_income_btn = ref)}>
                    + Add Income / Expense
                  </Button>
                </div>
              </div>
            </div>

            <div className="card_wrapper">
              <div className="row">
                <div className="col-xl-3 col-md-3 col-sm-6 col-12 mt-4" ref={(ref) => (tour_ref.current.home_net_income = ref)}>
                  <div className="card">
                    <div className="card-body">
                      <img className="circle" src={card1} alt="" />
                      <h3 className="card-title fw-bold">{newNet !== 'N/A' && formatCurrency(newNet)}</h3>
                      <div className="card_ico d-flex justify-content-between mt-3 align-items-center">
                        <p className="card-text dashboard-card-text fw-normal m-0">Net Income(ytd)</p>
                        <p className="m-0 pera_icon">
                          <img src={iconDolor} className="img-fluid" alt="" />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-md-3 col-sm-6 col-12 mt-4" ref={(ref) => (tour_ref.current.home_est_market_val = ref)}>
                  <div className="card">
                    <div className="card-body">
                      <img className="circle" src={card2} alt="" />
                      <h3 className="card-title fw-bold">{newMarket !== 'N/A' && formatCurrency(newMarket)}</h3>
                      <div className="card_ico1 d-flex justify-content-between mt-3 align-items-center">
                        <p className="card-text dashboard-card-text m-0">Est market value</p>
                        <p className="m-0 pera_icon1">
                          <img src={iconGroup} className="img-fluid" alt="" />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-md-3 col-sm-6 col-12 mt-4" ref={(ref) => (tour_ref.current.home_loan = ref)}>
                  <div className="card">
                    <div className="card-body">
                      <img className="circle" src={card4} alt="" />
                      <h3 className="card-title fw-bold">{formatCurrency(newLoan)}</h3>
                      <div className="card_ico3 d-flex justify-content-between mt-3 align-items-center">
                        <p className="card-text dashboard-card-text m-0">Loan</p>
                        <p className="m-0 pera_icon3">
                          <img src={iconLoan} className="img-fluid" alt="" />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-md-3 col-sm-6 col-12 mt-4" ref={(ref) => (tour_ref.current.home_net_equity = ref)}>
                  <div className="card">
                    <div className="card-body">
                      <img className="circle" src={card3} alt="" />
                      <h3 className="card-title fw-bold">{newEquity !== 'N/A' && formatCurrency(newEquity)}</h3>
                      <div className="card_ico2 d-flex justify-content-between mt-3 align-items-center">
                        <p className="card-text dashboard-card-text m-0">Net equity</p>
                        <p className="m-0 pera_icon2">
                          <img src={iconPie} className="img-fluid" alt="" />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Spin>

          <HowWorks />

          <Row className="mt-4 mb-3 pt-2 card_wrapper" gutter={20}>
            <Col xl={6} lg={8} md={9} sm={24} xs={24}>
              <h5 className="mt-2 mb-4" ref={(ref) => (tour_ref.current.home_loan_overview = ref)}>
                Loan Overview
              </h5>
              <Spin spinning={loading}>
                <div className="card bank-meta">
                  <div className="card-body">
                    <img className="circle" src={card1} alt="" />
                    {/* <Dropdown
                      menu={{
                        items,
                        onClick: onLoanClick,
                      }}
                      trigger={['click']}
                      className="loan_overview_select"
                    >
                      <Space>
                        {loanOverview.name || 'All'}
                        <DownOutlined />
                      </Space>
                    </Dropdown> */}
                    <Card bordered={true}>
                      <Statistic title="Interest Rate Overall" value={loanOverview.interest_rate?.toFixed(2) || 0} suffix="%" />
                    </Card>
                    <Card bordered={true}>
                      <Statistic title="Interest Rate Fixed" value={dData?.loan_review?.fixed?.interest_rate?.toFixed(2) || 0} suffix="%" />
                    </Card>
                    <Card bordered={true}>
                      <Statistic title="Interest Rate Variable" value={dData?.loan_review?.variable?.interest_rate?.toFixed(2) || 0} suffix="%" />
                    </Card>
                    <Card bordered={true}>
                      <Statistic title="Repayment" value={formatCurrency(loanOverview.emi || 0)} />
                    </Card>
                    <Card bordered={true}>
                      <Statistic title="Available Redraw" value={formatCurrency(loanOverview.available_redraw || 0)} />
                    </Card>
                  </div>
                </div>
              </Spin>
            </Col>
            <Col xl={18} lg={16} md={15} sm={24} xs={24} className="property-process-view">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h5 className="m-0" ref={(ref) => (tour_ref.current.home_pending_task = ref)}>
                  Pending Action / Reminder
                </h5>
                <Segmented
                  size="large"
                  options={[
                    {
                      label: 'Process View',
                      value: 'process',
                      icon: <SettingOutlined />,
                    },
                    {
                      label: 'Property View',
                      value: 'property',
                      icon: <HomeOutlined />,
                    },
                  ]}
                  value={viewValue}
                  onChange={setViewValue}
                />
              </div>
              <Spin spinning={notificationLoading} size="large">
                {propertyNotificationData && propertyNotificationData.length > 0 && viewValue === 'process' && (
                  <List
                    itemLayout="horizontal"
                    // grid={{ gutter: 16, column: 1 }}
                    dataSource={orderedGroupedData || []}
                    renderItem={(item, index) => (
                      <>
                        <List.Item key={index} className="shadow-sm p-4 mb-4 d-block border rounded bg-white">
                          <div className="d-flex justify-content-between align-items-center w-100">
                            <List.Item.Meta avatar={getIcon(item?.type)} title={<h6 className="fw-bold">{getTitle(item?.type, item.loan_id)}</h6>} description={<b>{item?.message}</b>} />
                            {!item?.complete && item.type !== 'add_product_request' && <div>{renderItem(item)}</div>}
                          </div>
                          {/* display property using grid */}
                          <Row className="mt-4" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{ marginLeft: '50px' }}>
                            {item?.property?.map((property_item, index) => (
                              <Col className="gutter-row pb-2" key={index} span={8}>
                                <span>
                                  <RightCircleFilled style={{ color: 'grey' }} />{' '}
                                  {item.type === 'add_product_request' ? (
                                    <span
                                      onClick={() =>
                                        navigate(`/property/${property_item.property_id}?tab=depreciation`, {
                                          state: { fromSameSite: true, transaction_id: property_item.transaction_id },
                                        })
                                      }
                                      className="cursor-pointer text-decoration-underline"
                                    >
                                      {property_item.property_name}
                                    </span>
                                  ) : item.type === 'check_property_loan' && item.loan_id === null ? (
                                    <span
                                      onClick={() => navigate(`/property/edit/${property_item.property_id}`, { state: { fromSameSite: true } })}
                                      className="cursor-pointer text-decoration-underline"
                                    >
                                      {property_item.property_name}
                                    </span>
                                  ) : (
                                    <span>{property_item.property_name}</span>
                                  )}
                                </span>
                              </Col>
                            ))}
                          </Row>
                        </List.Item>
                        {/* <List
                        itemLayout="horizontal"
                        dataSource={item.action_item?.filter((item) => !item.complete) || []}
                        renderItem={(item, index) => (
                          <List.Item key={index} style={item?.complete ? { background: '#f6f6f6' } : {}}>
                            <List.Item.Meta avatar={getIcon(item?.type)} title={getTitle(item?.type)} description={item?.message} />
                            {!item?.complete && <div>{renderItem(item)}</div>}
                          </List.Item>
                        )}
                      /> */}
                      </>
                    )}
                  />
                )}
                {propertyNotificationData && propertyNotificationData.length > 0 && viewValue === 'property' && (
                  // <Card className="card_inner overflow-auto">
                  <List
                    itemLayout="horizontal"
                    dataSource={propertyNotificationData || []}
                    renderItem={(item, index) => (
                      <List.Item className="shadow-sm p-3 mb-4 d-block border rounded bg-white" key={index}>
                        <List.Item.Meta title={<h6 className="fw-bold">{item.property_name}</h6>} />

                        <List
                          itemLayout="horizontal"
                          dataSource={item.action_item?.filter((item) => !item.complete) || []}
                          renderItem={(item, index) => (
                            <List.Item key={index} style={item?.complete ? { background: '#f6f6f6' } : {}}>
                              <List.Item.Meta avatar={getIcon(item?.type)} title={getTitle(item?.type)} description={item?.message} />
                              {!item?.complete && <div>{renderItem(item)}</div>}
                            </List.Item>
                          )}
                        />
                      </List.Item>
                    )}
                  />
                  // </Card>
                )}

                {!notificationLoading && propertyNotificationData.length < 1 && actionItemProperty.length < 1 && (
                  <Result
                    status="success"
                    title="Fantastic! 🎉 There are no pending tasks right now!!"
                    subTitle={
                      <div>
                        You can always, Add new property by clicking on the Add property button, Keep tabs on your finances by exploring the transaction menu, Stay updated with the notifications.
                      </div>
                    }
                    extra={[
                      <Button key={crypto.randomUUID()} onClick={() => navigate('/property/add')} className="property_btn">
                        + Add Property
                      </Button>,
                    ]}
                  />
                )}
                {!notificationLoading && propertyNotificationData.length < 1 && actionItemProperty.length > 0 && <NoProperty />}
                {notificationLoading && propertyNotificationData.length < 1 && <Card className="vh-100" />}
              </Spin>
            </Col>
          </Row>
        </div>
      </div>

      {/* EMAILNOW MODAL */}
      <Modal onCancel={() => setIsOpenEmail(false)} footer={null} centered width={550} style={{ zIndex: '1005', borderRadius: '1em' }} className="txtAccountentModal" open={isOpenEmail}>
        <div ref={ref}>
          Dear Property Manager, <br />
          <br />
          How are you? I hope you are doing well.
          <br />
          <br />
          Going forward, please add my below email to send my monthly rental summary for all my properties "{admin?.virtual_email}"
          <br />
          <br />
          Property Details as below : <br />
          {
            <ul className="mb-0">
              {propertyAddress?.property?.map((item, index) => (
                <li key={index}>{item.property_name}</li>
              ))}
            </ul>
          }
          {propertyAddress?.property_address && (
            <ul className="mb-0">
              <li>{propertyAddress?.property_address}</li>
            </ul>
          )}
          <br />
          Please confirm by email once this has been action. <br />
          <br />
          Have a nice day <br />
          Regards
        </div>

        <div className="p-2 text-end">
          <Button className="copy_btn3" onClick={() => copyText()}>
            Copy
          </Button>
          <Button className="copy_btn1" onClick={() => emailText()}>
            Send Mail
          </Button>
        </div>
      </Modal>

      {/* Consent modal */}
      <Consent data={dData} />
    </main>
  );
});

export default Home;

import { DownloadOutlined, DownOutlined, EditOutlined, ExclamationCircleOutlined, EyeFilled, FilterOutlined, PlusOutlined, PrinterOutlined, SettingOutlined, SyncOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Form,
  notification,
  Row,
  Select,
  Spin,
  DatePicker,
  Table,
  Space,
  Typography,
  Card,
  Radio,
  Segmented,
  Dropdown,
  Popover,
  Descriptions,
  Drawer,
  Tag,
  Modal,
  TreeSelect,
  Divider,
  Flex,
  Checkbox,
  Alert,
} from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { useLocation, useNavigate } from 'react-router-dom';
import { GetPropertyListWithOutLoan } from '../../../redux/actions/property/getPropertyListWithOutLoan.action';
import { GetViewReportData, GetReportEmailData, GetReportExcelData, GetReportTransactionData, ChangeLoanReportData, ChangeFixedInterestExpiry } from '../../../redux/actions/reports/getReport.action';
import { UserTypes, PropertyType, GstType, TrensectionType, CategoryType } from '../../common/userTypes';
import { GetCategoryList } from '../../../redux/actions/category/getCategoryList.action';
import CustomIcon from '../../sidebar/custIcon';
import editIcon from '../../../assets/images/edit.svg';
import DT from '../../common/DT';
import EditRecordExpenses from '../record_expenses/edit';
import NoProperty from '../../common/NoProperty';
import { GetBankList } from '../../../redux/actions/property/getBankList.action';
import { getPropertyTypeLabel, getTransactionTypeLabel } from '../../../utils/ConstLabel';
import './index.less';
import '../../../assets/less/index.less';
import { GoBackWithClick } from '../../common/GoBack';
import { amountFormat, formatCurrency, getEndDateWithTime, getStartDateWithTime, getYearList } from '../../../utils';
import Lock from './lock';
import DownloadStatementModal from '../BankConnection/DownloadStatementModal';
import DrawerDepreciation from '../property/DrawerDepreciation';
import Download from './download';

const { Option } = Select;
const { RangePicker } = DatePicker;
const { Column, ColumnGroup } = Table;
const { Text, Paragraph } = Typography;

const ExcludeClick = [17, 18, 15];

const convertTwoDecimalFloat = (amount) => {
  return parseFloat(amount.toFixed(2));
};

export default function Reports({ clientIds, autoRender = false, isTaxAccountant = false, clientOptions, isSwitching = false, propertyId = false, is_subscribed = false }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [productForm] = Form.useForm();
  const [loanreportFilterForm] = Form.useForm();
  const [reportData, setReportData] = useState([]);
  const [reportCategoryData, setReportCategoryData] = useState([]);
  const [reportType, setReportType] = useState(1);
  const [categoryId, setCategoryId] = useState(null);
  const dispatch = useDispatch();

  const [depreciationPropertyData, setDepreciationPropertyData] = useState(null);

  const [depreciationOpen, setDepreciationOpen] = useState(false);
  const [depreciationPropertyId, setDepreciationPropertyId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [propertyData, setPropertyData] = useState([]);
  const [allprop, setAllprop] = useState();
  const [dropfalse, setDropfalse] = useState(true);
  const [allfalse, setAllfalse] = useState(false);
  const admin = localStorage.getItem('admin');
  const { user_type = null } = admin ? JSON.parse(admin) : {};
  const currentClient = !clientIds ? localStorage.getItem('currentClient') : undefined;
  const [modal, contextHolder] = Modal.useModal();
  const notificationDta = useSelector((state) => state.getNotificationDataReducer);
  const clientDetail = useSelector((state) => state.getClientdetailByIdReducer);
  const isFinancialPlanner = notificationDta?.data?.result?.userData?.is_financial_planner;
  const is_free_trail_user = notificationDta?.data?.result?.userData?.is_free_trail;
  const is_free_trail = clientDetail?.data?.result?.is_free_trail;
  const isFreeTrailUser = user_type !== UserTypes.USER ? is_free_trail : is_free_trail_user;

  const [incomeCategorydata, setIncomeCategorydata] = useState([]);
  const [expenseCategorydata, setExpenseCategorydata] = useState([]);
  const [securityValue, setSecurityValue] = useState(false);
  const [interestExpiry, setInterestExpiry] = useState(false);

  const [dateType, setDateType] = useState('year');
  const [paramValues, setParamValues] = useState({});
  const [filterValues, setFilterValues] = useState({});
  const [visible, setVisible] = useState(false);
  const [visibleFilter, setVisibleFilter] = useState(false);
  const [openTransaction, setOpenTransaction] = useState(false);
  const [openTransactionEdit, setOpenTransactionEdit] = useState(false);
  const [transactionEditValue, setTransactionEditValue] = useState(null);
  const [transactionLoading, setTransactionLoading] = useState(false);
  const [transactionData, setTransactionData] = useState([]);
  const [tranParamValues, setTranParamValues] = useState({});
  const [bankData, setBankData] = useState([]);
  const [grandTotal, setGrandTotal] = useState(false);
  const [totalGrandTotal, setTotalGrandTotal] = useState(false);
  const [openDownloadStaement, setOpenDownloadStaement] = useState(false);

  const propertyDta = useSelector((state) => state.getPropertyListWithOutLoanReducer);
  const categoryDta = useSelector((state) => state.getCategoryListReducer);
  const bankList = useSelector((state) => state.getBankListReducer);
  const currentYear = dayjs().month() >= 6 ? dayjs().year() : dayjs().year() - 1;

  useEffect(() => {
    if (location?.state?.reload_change) {
      setReportType(1);
      productForm.setFieldsValue({
        report_type: 1,
        Property_type: ['All'],
        yearArray: [dayjs().year()],
        endDate: dayjs(),
        dateType: 'year',
      });

      setFilterValues({});
    }
  }, [location.state, productForm]);

  useEffect(() => {
    if (!clientIds) {
      dispatch(GetPropertyListWithOutLoan(currentClient));
    }
    if (![UserTypes.CHANNEL_PARTNER, UserTypes.SUB_CHANNEL_PARTNER].includes(user_type)) {
      dispatch(GetCategoryList());
    }
  }, [dispatch, currentClient, user_type, clientIds]);

  useEffect(() => {
    if (propertyDta && propertyDta.data.result && propertyDta.error === false) {
      let dt = propertyDta.data.result.map((elm) => {
        return {
          ...elm,
          key: elm.id,
          value: elm.id,
          title: `${elm.street_number} ${elm.street_name}`,
          property_type: elm.property_type,
        };
      });
      const dtp = dt.map((elm) => elm.id);
      setAllprop(dtp);
      setPropertyData(dt);
    }
  }, [propertyDta]);

  useEffect(() => {
    if (categoryDta && categoryDta.data.result && categoryDta.error === false) {
      let dt = categoryDta.data.result.map((e) => {
        return {
          value: e.id,
          label: e.category_name,
          income: !!(e.expense_type === 'Revenue' && e.transaction_type === 'In'),
          expense: !!(e.expense_type === 'Revenue' && e.transaction_type === 'Out'),
          cost: e.expense_type === 'Capital',
        };
      });
      let Incom = dt.filter((item) => ![false].includes(item.income));
      setIncomeCategorydata(Incom);
      // Sort the dt array based on category_name in ascending order
      dt.sort((a, b) => a.label.localeCompare(b.label));
      dt.push({
        value: CategoryType.DEPRECIATION_MANUAL,
        label: 'Depreciation Manual',
        income: false,
        expense: true,
        cost: false,
      });
      let Expense = dt.filter((item) => ![false].includes(item.expense));

      setExpenseCategorydata(Expense);

      // call api for single property report
      if (propertyId && (Object.keys(paramValues).length === 0 || paramValues?.reportType === 1) && (is_subscribed || isFreeTrailUser)) {
        handleViewReport({
          report_type: 1,
          dateType: 'year',
          yearArray: [currentYear, currentYear - 1, currentYear - 2],
        });
      }
    }
  }, [categoryDta]);

  useEffect(() => {
    if (bankList?.data?.result && bankList.error === false) {
      const drpdown = bankList?.data.result.map((e, i) => {
        return { value: e.id, label: e.shortName, key: crypto.randomUUID() };
      });
      setBankData(drpdown);
    }
  }, [bankList]);

  useEffect(() => {
    if (!bankList?.data?.result) {
      dispatch(GetBankList());
    }
  }, [dispatch, bankList?.data?.result]);

  const handleFilterReport = (values) => {
    handleViewReport({ ...filterValues, ...values });
  };

  const resetFilterReport = () => {
    loanreportFilterForm.resetFields();
    handleViewReport(filterValues);
  };

  const handleEmailReport = async () => {
    try {
      // Validate fields and get the form values
      const values = await productForm.validateFields();
      let params = { reportType: values.report_type };

      if (values.Property_type) {
        if (values.Property_type.includes('All')) params.propertyIds = allprop;
        else params.propertyIds = [...values.Property_type];
      }

      if (propertyId) {
        params.propertyIds = propertyId;
      }

      if (values.yearArray) {
        const sortedValues = [...values.yearArray].sort((a, b) => a - b);
        params.yearArray = sortedValues;
      }

      if (values.clientIds && values.clientIds.length > 0) {
        params.clientIds = values.clientIds;
      } else if (clientIds) {
        params.clientIds = clientIds;
      }

      if (values.dates) {
        params.startDate = getStartDateWithTime(values.dates[0]);
        params.endDate = getEndDateWithTime(values.dates[1]);
      }

      if (values.endDate) {
        params.endDate = getEndDateWithTime(values.endDate);
      }

      if (values.banks && values.banks.length > 0) {
        params.banks = values.banks;
      }
      if (values.interest_types && values.interest_types.length > 0) {
        params.interest_types = values.interest_types;
      }
      if (values.loan_types && values.loan_types.length > 0) {
        params.loan_types = values.loan_types;
      }

      handleEmail(params);
    } catch (errorInfo) {
      console.log('Failed:', errorInfo);
    }
  };

  const handleViewReport = useCallback(
    (values) => {
      setLoading(true);
      setVisible(false);
      setVisibleFilter(false);

      let params = { reportType: values.report_type };

      if (values.Property_type) {
        if (values.Property_type.includes('All')) params.propertyIds = allprop;
        else params.propertyIds = [...values.Property_type];
      }

      if (propertyId) {
        params.propertyIds = propertyId;
      }

      if (values.yearArray) {
        const sortedValues = [...values.yearArray].sort((a, b) => a - b);
        params.yearArray = sortedValues;
      }

      if (values.clientIds && values.clientIds.length > 0) {
        params.clientIds = values.clientIds;
      } else if (clientIds) {
        params.clientIds = clientIds;
      }

      if (values.dates) {
        params.startDate = getStartDateWithTime(values.dates[0]);
        params.endDate = getEndDateWithTime(values.dates[1]);
      }

      if (values.endDate) {
        params.endDate = getEndDateWithTime(values.endDate);
      }

      if (values.banks && values.banks.length > 0) {
        params.banks = values.banks;
      }
      if (values.interest_types && values.interest_types.length > 0) {
        params.interest_types = values.interest_types;
      }
      if (values.loan_types && values.loan_types.length > 0) {
        params.loan_types = values.loan_types;
      }

      dispatch(GetViewReportData(params, currentClient)).then((res) => {
        setLoading(false);

        if (res?.payload?.success) {
          setParamValues(params);
          setFilterValues(values);

          setReportData(res.payload.result || []);

          let data = {};
          let resultData = [];

          data.settlement_date = [
            { type: 'purchase_settlement_date', name: 'Purchase Date' },
            { type: 'property_sell_date', name: 'Sell Date' },
            { type: 'construction_date', name: 'Available On Rent' },
          ];

          if (values.report_type === 1) {
            data.income_title = {
              type: 'income_title',
              name: 'Income',
            };

            data.income = incomeCategorydata
              .filter((item) => item.value !== GstType.GST_IN)
              .map((income) => {
                return {
                  type: 'income',
                  category_name: income.label,
                  category_id: income.value,
                  ...income,
                };
              });

            data.income_total = {
              type: 'income_total',
              name: 'Gross Rent',
            };

            data.expense_title = {
              type: 'expense_title',
              name: 'Expense',
            };

            let depreciationData = [];

            const seenCategoryIds = new Set();

            if (res?.payload?.result) {
              res.payload.result.forEach((item) => {
                if (item?.reportData) {
                  item.reportData.forEach((subitem) => {
                    if (subitem?.depreciationTotalByCategory) {
                      subitem.depreciationTotalByCategory.forEach((e) => {
                        if (!seenCategoryIds.has(e.category_id)) {
                          depreciationData.push({
                            type: 'expense',
                            subType: 'depreciation',
                            value: e.category_id,
                            label: e.category_name,
                            ...e,
                          });
                          seenCategoryIds.add(e.category_id);
                        }
                      });
                    }
                  });
                }
              });
            }

            let rangeDepreciationData = [];

            if (res?.payload?.result) {
              const seenCategoryIds = new Set();

              rangeDepreciationData = res.payload.result.reduce((acc, item) => {
                if (item?.depreciationTotalByCategory) {
                  item.depreciationTotalByCategory.forEach((deprItem) => {
                    if (!seenCategoryIds.has(deprItem.category_id)) {
                      acc.push({
                        type: 'expense',
                        subType: 'depreciation',
                        value: deprItem.category_id,
                        label: deprItem.category_name,
                        ...deprItem,
                      });
                      seenCategoryIds.add(deprItem.category_id);
                    }
                  });
                }

                return acc;
              }, []);
            }

            data.expense = expenseCategorydata
              .filter((item) => item.value !== GstType.GST_OUT)
              .map((expense) => {
                return {
                  type: 'expense',
                  category_name: expense.label,
                  category_id: expense.value,
                  ...expense,
                };
              });

            if (depreciationData?.length > 0) {
              data.expense = [...data.expense, ...depreciationData];
            }

            if (rangeDepreciationData?.length > 0) {
              data.expense = [...data.expense, ...rangeDepreciationData];
            }

            data.expense_total = {
              type: 'expense_total',
              name: 'Total Expenses',
            };

            data.gst = [];
            if (res.payload.result.find((item) => item.propertyData.property_type === PropertyType.COMMERCIAL_PROPERTY)) {
              data.gst = [
                {
                  type: 'gst_title',
                  name: 'GST Summary',
                },
                {
                  type: 'gst_in',
                  category_name: 'GST Received',
                },
                {
                  type: 'gst_out',
                  category_name: 'GST Paid',
                },
                {
                  type: 'net_gst',
                  name: 'Net GST',
                },
              ];
            }

            data.netincome = {
              type: 'netincome',
              name: 'Net Rent Income (or Loss)',
            };

            if (values.yearArray) {
              setGrandTotal(
                res.payload.result
                  .filter((item) => item.propertyData.property_type !== PropertyType.OWNER_OCCUPIED_PROPERTY)
                  .map((item) => item.reportData.map((subitem) => subitem.netincome || 0).reduce((a, b) => a + b, 0))
                  .reduce((a, b) => a + b, 0),
              );
              setTotalGrandTotal(res.payload.result.map((item) => item.reportData.map((subitem) => subitem.netincome || 0).reduce((a, b) => a + b, 0)).reduce((a, b) => a + b, 0));
            } else {
              setGrandTotal(
                res.payload.result
                  .filter((item) => item.propertyData.property_type !== PropertyType.OWNER_OCCUPIED_PROPERTY)
                  .map((item) => item.netincome || 0)
                  .reduce((a, b) => a + b, 0),
              );
              setTotalGrandTotal(res.payload.result.map((item) => item.netincome || 0).reduce((a, b) => a + b, 0));
            }

            resultData = [...data.settlement_date, data.income_title, ...data.income, data.income_total, data.expense_title, ...data.expense, data.expense_total, data.netincome, ...data.gst];
          } else if (values.report_type === 2) {
            data.sell = [];
            const is_sell = res.payload.result.find((item) => item.propertyData.property_sell === 1);
            const subscribeProperty = res.payload.result.find((item) => item?.subscription !== false);
            if (is_sell) {
              data.sell = subscribeProperty
                ? [
                    ...subscribeProperty.costInCategoryData.map((less) => {
                      return {
                        type: 'sell',
                        ...less,
                      };
                    }),
                    {
                      type: 'net_selling',
                      name: 'Net Selling Price',
                    },
                  ]
                : [];
            }

            data.cost = subscribeProperty
              ? subscribeProperty.costOutCategoryData.map((cost) => {
                  return {
                    type: 'cost',
                    ...cost,
                  };
                })
              : [];

            data.cost_total = {
              type: 'cost_total',
              name: 'Total Capital Cost',
            };

            let lessDepreciationData = [];

            if (res?.payload?.result) {
              const seenCategoryIds = new Set();

              lessDepreciationData = res.payload.result.reduce((acc, item) => {
                // Check if this item has 'costDepreciationCategoryData'
                if (item?.costDepreciationCategoryData) {
                  item.costDepreciationCategoryData.forEach((e) => {
                    // Only add if we haven't seen this category_id before
                    if (!seenCategoryIds.has(e.category_id)) {
                      acc.push({
                        type: 'less',
                        subType: 'depreciation',
                        value: e.category_id,
                        label: e.category_name,
                        ...e,
                      });
                      seenCategoryIds.add(e.category_id);
                    }
                  });
                }
                return acc;
              }, []);
            }

            data.less_title = {
              type: 'less_title',
              name: 'Less',
            };

            data.less = subscribeProperty
              ? subscribeProperty.costNetOfDepreciation.map((less) => {
                  return {
                    type: 'less',
                    ...less,
                  };
                })
              : [];

            if (lessDepreciationData?.length > 0) {
              data.less = [...data.less, ...lessDepreciationData];
            }

            data.net_capital_cost = {
              type: 'net_capital_cost',
              name: 'Capital Costs ( Net of Depreciation)',
            };

            data.net_selling_price = [];
            if (is_sell) {
              data.net_selling_price = [
                {
                  type: 'net_selling_price',
                  name: 'Selling Price - Capital Cost',
                },
              ];
            }

            resultData = [...data.settlement_date, ...data.sell, ...data.cost, data.cost_total, data.less_title, ...data.less, data.net_capital_cost, ...data.net_selling_price];
          } else if (values.report_type === 3) {
            resultData = res.payload.result;
          }

          setReportCategoryData(resultData);
        } else if (res?.payload?.success === false && res?.payload?.result?.subscription === false) {
          if ([UserTypes.USER, UserTypes.TAXACCOUTANT, UserTypes.ADMIN_SUB_TAXACCOUNTANT].includes(user_type)) {
            modal.confirm({
              title: res?.payload?.message,
              icon: <ExclamationCircleOutlined />,
              okText: 'Subscribe',
              cancelText: 'Cancel',
              onOk: () => {
                isTaxAccountant ? navigate('/clients/client/subscription/checkout', { state: { fromSameSite: true } }) : navigate('/subscription/checkout', { state: { fromSameSite: true } });
              },
            });
          } else {
            modal.confirm({
              title: 'Kindly ask your client or super admin to subscribe for this property.',
              icon: <ExclamationCircleOutlined />,
              cancelText: 'Cancel',
            });
          }
        }
      });
    },
    [dispatch, currentClient, setLoading, setVisible, setVisibleFilter, user_type, clientIds, modal, incomeCategorydata, expenseCategorydata, allprop, navigate, isTaxAccountant, reportData],
  );

  useEffect(() => {
    if (user_type === UserTypes.CHANNEL_PARTNER || user_type === UserTypes.SUB_CHANNEL_PARTNER || (isFinancialPlanner && autoRender)) {
      handleViewReport({ report_type: 3, clientIds });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientIds, user_type, autoRender, isFinancialPlanner]);

  const handleEmail = async (param = null) => {
    setLoading(true);
    const paramsValues = param || paramValues;
    dispatch(GetReportEmailData(paramsValues, currentClient)).then((data) => {
      if (data.success === true) {
        notification.success({
          message: 'Success',
          description: data.message,
          duration: 10,
        });
        setLoading(false);
      } else {
        notification.error({
          message: 'Failure',
          description: data.message || 'Something went wrong!',
          duration: 10,
        });
        setLoading(false);
      }
    });
  };

  const handleXlsxDownload = async () => {
    setLoading(true);
    dispatch(GetReportExcelData(paramValues, currentClient)).then((data) => {
      if (data.success === true) {
        notification.success({
          message: 'Success',
          description: data.message,
          duration: 10,
        });
        const link = document.createElement('a');
        link.href = data.result;
        link.download = data.result;
        link.click();
        setLoading(false);
      } else {
        notification.error({
          message: 'Failure',
          description: data.message || 'Something went wrong!',
          duration: 10,
        });
        setLoading(false);
      }
    });
  };

  const handleLoanReportChange = (paramValues) => {
    setLoading(true);
    onChangeSecurityOpen();
    dispatch(ChangeLoanReportData(paramValues, currentClient)).then((data) => {
      if (data.success === true) {
        notification.success({
          message: 'Success',
          description: data.message,
          duration: 10,
        });
        handleViewReport(filterValues);
      } else {
        notification.error({
          message: 'Failure',
          description: data.message || 'Something went wrong!',
          duration: 10,
        });
        setLoading(false);
      }
    });
  };

  const handleFixedInterestExpiryChange = (paramValues) => {
    setLoading(true);
    onChangeInterestExpiryOpen();
    dispatch(ChangeFixedInterestExpiry(paramValues, currentClient)).then((data) => {
      if (data.success === true) {
        notification.success({
          message: 'Success',
          description: data.message,
          duration: 10,
        });
        handleViewReport(filterValues);
      } else {
        notification.error({
          message: 'Failure',
          description: data.message || 'Something went wrong!',
          duration: 10,
        });
        setLoading(false);
      }
    });
  };

  const chnagedrop = (e) => {
    if (e.includes('All')) {
      setDropfalse(true);
      setAllfalse(false);
    } else if (e.length === 0) {
      setAllfalse(false);
      setDropfalse(false);
    } else {
      setAllfalse(true);
    }
  };

  const onClickCategoryValue = (propertyId, categoryId, year = null) => {
    setTransactionLoading(true);
    const parm = { propertyId, categoryId };
    if (year) {
      parm.year = year;
    } else {
      parm.startDate = paramValues.startDate;
      parm.endDate = paramValues.endDate;
    }

    setTranParamValues({ ...parm });

    dispatch(GetReportTransactionData(parm, currentClient)).then((res) => {
      if (res.payload.success === true) {
        setTransactionData(res.payload.result);
      }

      setTransactionLoading(false);
    });
    setOpenTransaction(true);
  };

  const onClickDepreciation = (propertyData, categoryId, year = null, record = null) => {
    setCategoryId(categoryId);
    setDepreciationPropertyData({ ...propertyData, category_name: record.category_name });
    setDepreciationOpen(true);
    setDepreciationPropertyId(propertyData.id);
  };

  const getValueofReport = (record, item, propertyData) => {
    let amount = 0;
    if (record.type === 'income_total') {
      return <b>{amountFormat(item.incomeTotal || 0)}</b>;
    } else if (record.type === 'expense_total') {
      return <b>{amountFormat(item.expenseTotal || 0)}</b>;
    } else if (record.type === 'netincome') {
      return (
        <Text type={item.netincome > 0 ? 'success' : 'danger'}>
          <b>{amountFormat(item.netincome || 0)}</b>
        </Text>
      );
      // } else if (record?.category_id === GstType.GST_IN) {
      //   return amountFormat(propertyData.property_type !== PropertyType.COMMERCIAL_PROPERTY ? item.gst_in || 0 : 0);
      // } else if (record?.category_id === GstType.GST_OUT) {
      //   return amountFormat(propertyData.property_type !== PropertyType.COMMERCIAL_PROPERTY ? item.gst_out || 0 : 0);
    } else if (record.type === 'gst_in') {
      return amountFormat(propertyData.property_type === PropertyType.COMMERCIAL_PROPERTY ? item.gst_in || 0 : 0);
    } else if (record.type === 'gst_out') {
      return amountFormat(propertyData.property_type === PropertyType.COMMERCIAL_PROPERTY ? item.gst_out || 0 : 0);
    } else if (record.type === 'net_gst') {
      return <b>{amountFormat(convertTwoDecimalFloat(propertyData.property_type === PropertyType.COMMERCIAL_PROPERTY ? item.gst_in - item.gst_out : 0))}</b>;
    } else if (record.type === 'income') {
      amount = item?.incomeTotalByCategory?.find((category) => category.category_id === record.category_id)?.amount || 0;
      return amount > 0 && !ExcludeClick.includes(record.category_id) ? (
        <span className="cursor-pointer" onClick={() => onClickCategoryValue(propertyData.id, record.category_id, item?.year)}>
          {amountFormat(amount)}
        </span>
      ) : (
        amountFormat(amount)
      );
    } else if (record.type === 'expense') {
      amount =
        item?.expenseTotalByCategory?.find((category) => category.category_id === record.category_id)?.amount ||
        item?.depreciationTotalByCategory?.find((category) => category.category_id === record.category_id)?.amount ||
        0;

      if (record?.subType === 'depreciation') {
        return amount > 0 ? (
          <span
            className="cursor-pointer"
            onClick={() => {
              onClickDepreciation(propertyData, record.category_id, item?.year, record);
            }}
          >
            {amountFormat(amount)}
          </span>
        ) : (
          amountFormat(amount)
        );
      }

      return amount > 0 && !ExcludeClick.includes(record.category_id) ? (
        <span className="cursor-pointer" onClick={() => onClickCategoryValue(propertyData.id, record.category_id, item?.year)}>
          {amountFormat(amount)}
        </span>
      ) : (
        amountFormat(amount)
      );
    } else if (record.type === 'sell') {
      amount = propertyData.property_sell === 1 ? item.costInCategoryData.find((sell) => sell.category_id === record.category_id)?.amount || 0 : 0;
      return amount > 0 && !ExcludeClick.includes(record.category_id) ? (
        <span className="cursor-pointer" onClick={() => onClickCategoryValue(propertyData.id, record.category_id, item?.year)}>
          {amountFormat(amount)}
        </span>
      ) : (
        amountFormat(amount)
      );
    } else if (record.type === 'net_selling') {
      return <b>{amountFormat(item.costInTotalAmount || 0)}</b>;
    } else if (record.type === 'cost') {
      amount = item?.costOutCategoryData?.find((cost) => cost.category_id === record.category_id)?.amount || 0;
      return amount > 0 && !ExcludeClick.includes(record.category_id) ? (
        <span className="cursor-pointer" onClick={() => onClickCategoryValue(propertyData.id, record.category_id, item?.year)}>
          {amountFormat(amount)}
        </span>
      ) : (
        amountFormat(amount)
      );
    } else if (record.type === 'cost_total') {
      return <b>{amountFormat(item.costOutTotalAmount || 0)}</b>;
    } else if (record.type === 'less') {
      amount =
        item?.costNetOfDepreciation?.find((cost) => cost.category_name === record.category_name)?.amount ||
        item?.costDepreciationCategoryData?.find((cost) => cost.category_name === record.category_name)?.amount ||
        0;

      if (record?.subType === 'depreciation') {
        return amount > 0 ? (
          <span
            className="cursor-pointer"
            onClick={() => {
              onClickDepreciation(propertyData, record.category_id, item?.year, record);
            }}
          >
            {amountFormat(amount)}
          </span>
        ) : (
          amountFormat(amount)
        );
      }

      return amountFormat(amount);
    } else if (record.type === 'net_capital_cost') {
      return <b>{amountFormat(item.netCapitalCostOfDepreciation || 0)}</b>;
    } else if (record.type === 'net_selling_price') {
      return <b>{amountFormat(convertTwoDecimalFloat(item.costInTotalAmount - item.netCapitalCostOfDepreciation) || 0)}</b>;
    } else if (record.type === 'purchase_settlement_date') {
      return propertyData.purchase_settlement_date ? dayjs(propertyData.purchase_settlement_date).format('DD-MM-YYYY') : '-';
    } else if (record.type === 'construction_date') {
      return propertyData.construction_date ? dayjs(propertyData.construction_date).format('DD-MM-YYYY') : '-';
    } else if (record.type === 'property_sell_date') {
      return propertyData.property_sell_date ? dayjs(propertyData.property_sell_date).format('DD-MM-YYYY') : '-';
    } else {
      return 0;
    }
  };

  const handleDateTypeChange = (value) => {
    setDateType(value);
  };

  const handleReportTypeChange = (element) => {
    setReportType(element?.target?.value || 1);
  };

  const handleExportButton = (element) => {
    if (element.key === 'email') {
      handleEmail();
    } else if (element.key === 'xlsx') {
      handleXlsxDownload();
    }
  };

  const getFilter = () => (
    <Form
      layout="vertical"
      name="reportFilterForm"
      form={productForm}
      initialValues={{
        dateType: 'year',
        Property_type: ['All'],
        report_type: reportType,
        yearArray: propertyId && (is_subscribed || isFreeTrailUser) ? [currentYear, currentYear - 1, currentYear - 2] : [dayjs().month() >= 6 ? dayjs().year() : dayjs().year() - 1],
        endDate: dayjs(),
      }}
      autoComplete="off"
      onFinish={handleViewReport}
      wrapperCol={
        Object.keys(filterValues).length < 1
          ? {
              xs: { span: 24 },
              sm: { span: 24 },
              md: { span: 18 },
              lg: { span: 16 },
              xl: { span: 16 },
              xxl: { span: 16 },
            }
          : {}
      }
    >
      <Form.Item name="report_type" label="Report Type">
        <Radio.Group onChange={handleReportTypeChange}>
          <Space direction="vertical">
            <Radio value={1}>Income & Expenditure</Radio>
            <Radio value={2}>Capital Cost</Radio>
            {(user_type === UserTypes.USER || isFinancialPlanner) && <Radio value={3}>Loan Summary</Radio>}
          </Space>
        </Radio.Group>
      </Form.Item>
      {(reportType === 1 || reportType === 2) && !propertyId && (
        <Form.Item
          name="Property_type"
          label="Properties"
          rules={[
            {
              required: true,
              message: 'Please select property',
            },
          ]}
        >
          <Select
            maxTagCount="responsive"
            id="select_property"
            mode="multiple"
            style={{ width: '100%', textAlign: 'left' }}
            placeholder="Select Property"
            onChange={(e) => chnagedrop(e)}
            optionFilterProp="children"
          >
            {allfalse ? null : <> {propertyDta?.data?.result?.length === 0 ? null : <Option value="All">- - All - -</Option>}</>}
            {!dropfalse ? (
              <>
                {propertyData.map((elm, key) => {
                  return (
                    <Option value={elm.id} key={elm.id}>
                      {elm.street_number} {elm.street_name}
                    </Option>
                  );
                })}{' '}
              </>
            ) : (
              ''
            )}
          </Select>
        </Form.Item>
      )}

      {reportType === 1 && (
        <>
          <Form.Item name="dateType" label="Duration" required>
            <Segmented
              onChange={handleDateTypeChange}
              options={[
                {
                  label: 'Financial Year',
                  value: 'year',
                },
                {
                  label: 'Custom Date',
                  value: 'date',
                },
              ]}
            />
          </Form.Item>
          {dateType === 'year' && (
            <Form.Item
              name="yearArray"
              rules={[
                {
                  required: true,
                  message: 'Please select year',
                },
              ]}
            >
              <Select mode="multiple" maxTagCount="responsive" options={getYearList() || []} />
            </Form.Item>
          )}
          {dateType === 'date' && (
            <Form.Item
              name="dates"
              rules={[
                {
                  required: true,
                  message: 'Please select custom date',
                },
              ]}
            >
              <RangePicker format={'DD-MM-YYYY'} />
            </Form.Item>
          )}
        </>
      )}
      {reportType === 2 && (
        <Form.Item
          label="Date"
          name="endDate"
          rules={[
            {
              required: true,
              message: 'Please select date',
            },
          ]}
        >
          <DatePicker format="DD-MM-YYYY" placeholder="Date" />
        </Form.Item>
      )}
      <Space className="pt-3">
        <div className="text-center login_button text-md-start">
          <button type="submit" disabled={loading}>
            Generate Report
          </button>
        </div>
        {(reportType === 1 || reportType === 2) && Object.keys(filterValues).length < 1 && (
          <div className="text-center login_button text-md-start">
            <button type="button" onClick={handleEmailReport} disabled={loading}>
              Email Report
            </button>
          </div>
        )}
      </Space>
    </Form>
  );

  const getLoanFilter = () => (
    <Form
      layout="vertical"
      name="loanreportFilterForm"
      form={loanreportFilterForm}
      autoComplete="off"
      onFinish={handleFilterReport}
      wrapperCol={
        Object.keys(filterValues).length < 1
          ? {
              xs: { span: 24 },
              sm: { span: 24 },
              md: { span: 18 },
              lg: { span: 14 },
              xl: { span: 10 },
              xxl: { span: 10 },
            }
          : {}
      }
    >
      {clientIds && (
        <Form.Item name="clientIds" label="Select Client">
          <Select
            placeholder="Select Client"
            className="bank_name"
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input) || (option?.label.toUpperCase() ?? '').includes(input)}
            options={clientOptions}
            mode="multiple"
            maxTagCount="responsive"
          />
        </Form.Item>
      )}
      <Form.Item name="banks" label="Banks">
        <Select
          placeholder="Select Bank"
          className="bank_name"
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input) || (option?.label.toUpperCase() ?? '').includes(input)}
          options={bankData}
          mode="multiple"
          maxTagCount="responsive"
        />
      </Form.Item>
      <Form.Item name="interest_types" label="Interest Type">
        <Checkbox.Group>
          <Checkbox value={'VARIABLE'}>Variable</Checkbox>
          <Checkbox value={'FIXED'}>Fixed</Checkbox>
        </Checkbox.Group>
      </Form.Item>
      <Form.Item name="loan_types" label="Loan Type">
        <Checkbox.Group>
          <Space direction="vertical">
            <Checkbox value={'PRINCIPAL_AND_INTEREST'}>Principal and Interest Repayment</Checkbox>
            <Checkbox value={'INTEREST_ONLY'}>Interest only Repayment</Checkbox>
          </Space>
        </Checkbox.Group>
      </Form.Item>

      <Space align="horizontal">
        <div className="text-center login_button">
          <button type="submit" disabled={loading}>
            Filter
          </button>
        </div>
        <div className="text-center login_button">
          <button onClick={() => resetFilterReport()}>Reset</button>
        </div>
      </Space>
    </Form>
  );

  const onCloseTransaction = () => {
    setOpenTransaction(false);
  };

  const onCloseTransactionEdit = () => {
    setOpenTransactionEdit(false);
  };

  const onUpdateTransaction = () => {
    onCloseTransactionEdit();
    onClickCategoryValue(tranParamValues.propertyId, tranParamValues.categoryId, tranParamValues?.year);
    handleViewReport(filterValues);
  };

  const onUpdateDepreciation = () => {
    handleViewReport(filterValues);
    return true;
  };

  const columns = [
    {
      title: 'Transaction Source',
      dataIndex: 'transaction_type',
      key: 'expense_type',
      render: (_text, record) => {
        return getTransactionTypeLabel(Number(record?.transaction_type));
      },
    },
    {
      title: 'Date',
      dataIndex: 'transaction_date',
      key: 'expense_date',
      render: (text) => {
        const formattedDate = dayjs(text).format('DD-MM-YYYY');
        return <span>{formattedDate}</span>;
      },
    },
    {
      title: 'Price',
      dataIndex: 'amount',
      key: 'amount',
      render: (text, record) => {
        const prices = text?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return (
          <span
            className="text-decoration-underline cursor-pointer"
            onClick={(e) => {
              if (
                [
                  TrensectionType.RENT_TYPE_TRANSACTION,
                  TrensectionType.MANUAL_TYPE_TRANSACTION,
                  TrensectionType.SETTLEMENT_TYPE_TRANSACTION,
                  TrensectionType.SUBSCRIPTION_TYPE_TRANSACTION,
                  TrensectionType.BASIQ_TYPE_TRANSACTION,
                ].includes(Number(record.transaction_type))
              ) {
                e.preventDefault();
                setOpenTransactionEdit(true);
                setTransactionEditValue({ id: record.id, state: { TransectionType: Number(record.transaction_type), PropertyId: record?.property_id, LoanId: record?.loan_id } });
              }
            }}
          >
            {`$${prices}`}
          </span>
        );
      },
    },
    {
      title: 'Edit/View',
      dataIndex: 'action',
      key: 'action',
      render: (_text, record) => {
        if (
          ![
            TrensectionType.RENT_TYPE_TRANSACTION,
            TrensectionType.MANUAL_TYPE_TRANSACTION,
            TrensectionType.SETTLEMENT_TYPE_TRANSACTION,
            TrensectionType.SUBSCRIPTION_TYPE_TRANSACTION,
            TrensectionType.BASIQ_TYPE_TRANSACTION,
          ].includes(Number(record.transaction_type))
        ) {
          return '-';
        }
        return (
          <Row>
            <Col>
              <Button
                className="mr-2"
                type="primary"
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTransactionEdit(true);
                  setTransactionEditValue({ id: record.id, state: { TransectionType: Number(record.transaction_type), PropertyId: record?.property_id, LoanId: record?.loan_id } });
                }}
                icon={record.locked ? <EyeFilled /> : <CustomIcon icon={editIcon} />}
                size="small"
              />
            </Col>
          </Row>
        );
      },
    },
  ];

  const onChangeSecurityOpen = () => {
    setSecurityValue(false);
  };

  const onChangeInterestExpiryOpen = () => {
    setInterestExpiry(false);
  };

  const loan_columns = [];

  clientIds &&
    loan_columns.push(
      ...[
        {
          title: <span>User Name</span>,
          dataIndex: 'user_name',
          key: 'user_name',
          align: 'left',
          fixed: true,
          render: (text, record) => {
            if (record.divider) {
              return '';
            }
            return record.extra ? '' : text;
          },
        },
        {
          title: <span>User Email</span>,
          dataIndex: 'user_email',
          key: 'user_email',
          align: 'left',
          fixed: true,
          render: (text, record) => {
            if (record.divider) {
              return '';
            }
            return record.extra ? '' : text;
          },
        },
      ],
    );

  loan_columns.push(
    ...[
      {
        title: 'Bank Name',
        dataIndex: 'bank_name',
        key: 'bank_name',
        align: 'center',
        fixed: true,
        render: (text, record) => {
          if (record.divider) {
            return '';
          }
          return record.extra ? '' : text;
        },
      },
      {
        title: 'BSB',
        dataIndex: 'bank_bsb_number',
        key: 'bank_bsb_number',
        align: 'center',
        fixed: true,
        render: (text, record) => {
          if (record.divider) {
            return '';
          }
          return record.extra ? '' : text;
        },
      },
      {
        title: 'Account No',
        dataIndex: 'bank_account_number',
        key: 'bank_account_number',
        align: 'center',
        fixed: true,
        render: (text, record) => {
          if (record.divider) {
            return '';
          }
          return record.extra ? '' : text;
        },
      },
      {
        title: 'Interest Type',
        dataIndex: 'interest_type',
        align: 'center',
        key: 'interest_type',
        render: (text, record) => {
          if (record.divider) {
            return '';
          }
          if (record.extra) {
            return '';
          }
          return text ? <span style={{ textTransform: 'capitalize' }}>{text?.toLowerCase()}</span> : '-';
        },
      },
      {
        title: 'Loan Type*',
        dataIndex: 'loan_replacement_type',
        key: 'loan_replacement_type',
        align: 'center',
        render: (text, record) => {
          if (record.divider) {
            return '';
          }
          if (record.extra) {
            return '';
          }
          return text ? (text === 'PRINCIPAL_AND_INTEREST' ? 'P & I' : 'Int Only') : '-';
        },
      },
      {
        title: 'Product Name',
        dataIndex: 'product_name',
        key: 'product_name',
        align: 'center',
        render: (text, record) => {
          if (record.divider) {
            return '';
          }
          if (record.extra) {
            return '';
          }
          return text ? <span style={{ whiteSpace: 'nowrap' }}>{text}</span> : '-';
        },
      },
      // {
      //   title: 'Remaining Loan Term (Years)',
      //   dataIndex: 'remaining_loan_term',
      //   key: 'remaining_loan_term',
      //   align: 'center',
      //   render: (text, record) => {
      //     if (record.divider) {
      //       return '';
      //     }
      //     if (record.extra) {
      //       return '';
      //     }
      //     return text;
      //   },
      // },
      {
        title: 'Start Date',
        dataIndex: 'loan_start_date',
        key: 'loan_start_date',
        align: 'center',
        render: (text, record) => {
          if (record.divider) {
            return '';
          }
          if (record.extra) {
            return '';
          }
          return text ? dayjs(text).format('DD/MM/YYYY') : '-';
        },
      },
      {
        title: 'End Date',
        dataIndex: 'loan_end_date',
        key: 'loan_end_date',
        align: 'center',
        render: (text, record) => {
          if (record.divider) {
            return '';
          }
          if (record.extra) {
            return '';
          }
          return text ? dayjs(text).format('DD/MM/YYYY') : '-';
        },
      },
      {
        title: 'Interest Rate',
        dataIndex: 'interest_rate',
        key: 'interest_rate',
        align: 'center',
        render: (text, record) => {
          if (record.divider) {
            return '';
          }
          if (record.extra) {
            return <b>{record?.interest_rate_average || 0}%</b>;
          }
          return <span>{text || 0}%</span>;
        },
      },
      {
        title: <span>Loan Amount $$</span>,
        dataIndex: 'loan_balance',
        key: 'loan_balance',
        align: 'right',
        render: (text, record) => {
          if (record.divider) {
            return '';
          }
          if (record.extra) {
            return <b>{amountFormat(convertTwoDecimalFloat(record?.loan_amount_total)) || 0}</b>;
          }
          return text ? amountFormat(text) : '-';
        },
      },
      {
        title: <span>Market Value $$</span>,
        dataIndex: 'market_value',
        key: 'market_value',
        align: 'right',
        render: (text, record) => {
          if (record.divider) {
            return '';
          }
          if (record.extra) {
            return <b>{amountFormat(record.market_value_total) || 0}</b>;
          } else if (record.extra_length) {
            return '';
          } else if (record.securities.length > 0) {
            return (
              <>
                <Space direction="vertical">{text.map((item) => `${amountFormat(item) || 0}`)}</Space>
                {text.length > 1 && (
                  <>
                    <Divider style={{ margin: '8px 0' }} />
                    <b>{amountFormat(record.market_value_total) || 0}</b>
                  </>
                )}
              </>
            );
          } else {
            return `-`;
          }
        },
      },
      {
        title: <span>Net Equity $$</span>,
        dataIndex: 'net_equity',
        key: 'net_equity',
        align: 'right',
        render: (text, record) => {
          if (record.divider) {
            return '';
          }
          if (record.extra) {
            return <b>{text ? amountFormat(convertTwoDecimalFloat(text)) : 0}</b>;
          } else if (record.extra_length) {
            return '';
          } else {
            return text ? amountFormat(convertTwoDecimalFloat(text)) : 0;
          }
        },
      },
      {
        title: 'LVR (%)',
        dataIndex: 'lvr',
        key: 'lvr',
        align: 'center',
        render: (text, record) => {
          if (record.divider) {
            return '';
          }
          if (record.extra) {
            return <b>{record?.total_lvr}</b>;
          }
          return text;
        },
      },
    ],
  );

  clientIds
    ? loan_columns.push({
        title: 'Property Security',
        dataIndex: 'securities',
        width: 200,
        key: 'securities',
        align: 'center',
        render: (text, record) => {
          if (record.divider) {
            return '';
          }
          if (record.extra) {
            return '';
          }
          const propertyids = text ? text.map((item) => item.id) : [];
          return (
            <>
              <Space direction="vertical">
                {propertyids && text.map((item) => <span style={{ whiteSpace: 'nowrap' }}>{`${item.street_number} ${item.street_name}, ${item.suburb}, ${item.state}, ${item.postcode}`}</span>)}
              </Space>
              {propertyids.length < 1 && '-'}
            </>
          );
        },
      })
    : loan_columns.push({
        title: 'Property Security',
        width: 200,
        children: [
          {
            title: 'Name',
            dataIndex: 'securities',
            width: 200,
            key: 'securities',
            align: 'center',
            render: (text, record) => {
              if (record.divider) {
                return '';
              }
              if (record.extra) {
                return '';
              }
              const propertyids = text ? text.map((item) => item.id) : [];
              return (
                <>
                  <Space direction="vertical">
                    {propertyids && text.map((item) => <span style={{ whiteSpace: 'nowrap' }}>{`${item.street_number} ${item.street_name}, ${item.suburb}, ${item.state}, ${item.postcode}`}</span>)}
                  </Space>
                  {propertyids.length < 1 && '-'}
                </>
              );
            },
          },
          {
            title: 'Action',
            dataIndex: 'securities',
            key: 'securities',
            align: 'center',
            render: (text, record) => {
              if (record.divider) {
                return '';
              }
              if (record.extra) {
                return '';
              }
              const propertyids = text ? text.map((item) => item.id) : [];
              return (
                <span className="cursor-pointer mb-0" onClick={() => setSecurityValue(record)}>
                  {propertyids.length > 0 ? <EditOutlined /> : <PlusOutlined />}
                </span>
              );
            },
          },
        ],
      });

  loan_columns.push(
    ...[
      {
        title: <span>Repayment $$</span>,
        dataIndex: 'min_instalment_amount',
        key: 'min_instalment_amount',
        align: 'right',
        render: (text, record) => {
          if (record.divider) {
            return '';
          }
          if (record.extra) {
            return <b>{amountFormat(record.min_instalment_amount_total) || 0}</b>;
          }
          return text ? amountFormat(text) : '-';
        },
      },
      {
        title: <span>Redraw $$ </span>,
        dataIndex: 'available_funds',
        key: 'available_funds',
        align: 'right',
        render: (text, record) => {
          if (record.divider) {
            return '';
          }
          if (record.extra) {
            return <b>{amountFormat(record.available_funds_total) || 0}</b>;
          }
          return `${text ? amountFormat(text) : '-'}`;
        },
      },
      {
        title: 'Fixed Interest Expiry',
        dataIndex: 'fixed_interst_expiry',
        key: 'fixed_interst_expiry',
        align: 'center',
        render: (text, record) => {
          if (record.divider) {
            return '';
          }
          if (record.extra) {
            return '';
          }
          if (record.interest_type && record.interest_type !== 'VARIABLE' && !clientIds) {
            return (
              <span className="cursor-pointer" onClick={() => setInterestExpiry(record)}>
                {text ? dayjs(text).format('DD/MM/YYYY') : 'Add'}
              </span>
            );
          }
          if (clientIds) {
            return text ? dayjs(text).format('DD/MM/YYYY') : '-';
          }
          return `-`;
        },
      },
    ],
  );

  const onCloseDownloadStaement = () => {
    setOpenDownloadStaement(false);
  };

  return (
    <>
      <main className={user_type === UserTypes.USER ? 'page-content report-content' : 'report-content'}>
        <div className={user_type === UserTypes.USER && !propertyId ? 'container-fluid bg-dash-back' : 'overflow-hidden'}>
          <div className={user_type === UserTypes.USER && !propertyId ? 'layout-specing' : ''}>
            <Row className={`${propertyId ? 'mt-3' : ''} align-items-center`}>
              <Col xs={24} sm={12} md={9} lg={8} xl={7} xxl={7} span={4}>
                {Object.keys(filterValues).length > 0 && (
                  <div className="d-flex align-items-center">
                    {!propertyId && (user_type === UserTypes.USER || isTaxAccountant) && <GoBackWithClick handleOnClick={() => setFilterValues({})} />}
                    <h5 className="mb-sm-0 mb-3 mb-sm-0">
                      {clientIds
                        ? `Loan Overview Report`
                        : filterValues.report_type === 1
                        ? `Income & Expenditure Report`
                        : filterValues.report_type === 2
                        ? `Capital Cost Report`
                        : `Loan Summary Report`}
                    </h5>
                  </div>
                )}
              </Col>
              <Col xs={24} sm={24} md={15} lg={15} xl={17} xxl={17} span={20} className="text-sm-end">
                {Object.keys(filterValues).length > 0 && (
                  <Space>
                    {filterValues.report_type === 3 && (
                      <>
                        {!clientIds && [UserTypes.CHANNEL_PARTNER, UserTypes.SUB_CHANNEL_PARTNER].includes(user_type) && (
                          <Button className="property_btn1_reload btn" icon={<DownloadOutlined />} onClick={() => setOpenDownloadStaement(true)}>
                            Download Statements
                          </Button>
                        )}
                        <Popover content={<div className="p-2">{getLoanFilter()}</div>} trigger={['click']} open={visibleFilter} onOpenChange={() => setVisibleFilter(!visibleFilter)}>
                          <Button icon={<FilterOutlined />} className="property_btn1_icon btn">
                            Filter
                          </Button>
                        </Popover>
                      </>
                    )}
                    <Button className="property_btn1_reload btn" icon={<SyncOutlined />} title="Reload" onClick={() => handleViewReport(filterValues)}>
                      Reload
                    </Button>
                    {![UserTypes.CHANNEL_PARTNER, UserTypes.SUB_CHANNEL_PARTNER].includes(user_type) && !clientIds && (
                      <Popover content={<div className="p-2 w-100">{getFilter()}</div>} trigger={['click']} open={visible} onOpenChange={() => setVisible(!visible)}>
                        <Button icon={<SettingOutlined />} className="property_btn1_icon">
                          Customize Report
                        </Button>
                      </Popover>
                    )}
                    {reportCategoryData.length > 0 && (
                      <Dropdown
                        menu={{
                          items: [
                            {
                              label: 'Email Report',
                              key: 'email',
                            },
                            {
                              label: 'XLSX (Microsoft Excel)',
                              key: 'xlsx',
                            },
                          ],
                          onClick: handleExportButton,
                        }}
                      >
                        <Button icon={<PrinterOutlined />} className="property_btn1_icon">
                          <Space>
                            Export As
                            <DownOutlined />
                          </Space>
                        </Button>
                      </Dropdown>
                    )}
                  </Space>
                )}
              </Col>
            </Row>
            {Object.keys(filterValues).length < 1 && propertyDta && propertyDta.isLoading && (
              <div className="mt-3 text-center">
                <Spin spinning={propertyDta.isLoading} size="large" />
              </div>
            )}
            {!isSwitching && propertyDta && !propertyDta.isLoading && propertyDta.data?.result?.length < 1 && (
              <Card className="box_shadow mt-3">
                <NoProperty isTaxAccountant={isTaxAccountant} />
              </Card>
            )}
            {![UserTypes.CHANNEL_PARTNER, UserTypes.SUB_CHANNEL_PARTNER].includes(user_type) &&
              !propertyId &&
              !propertyDta.isLoading &&
              propertyDta.data?.result?.length > 0 &&
              Object.keys(filterValues).length < 1 && (
                <Spin spinning={loading} size="large">
                  <Row className="mb-3 card_wrapper" gutter={20}>
                    <Col xl={14} lg={14} md={12} sm={24} xs={24}>
                      <Card title="Generate Report" className="box_shadow mt-3" headStyle={{ backgroundColor: '#f2f8f8' }}>
                        {getFilter()}
                      </Card>
                    </Col>
                    {!propertyId && (
                      <Col xl={10} lg={10} md={12} sm={24} xs={24}>
                        <Download isTaxAccountant={isTaxAccountant} />
                        <Lock />
                      </Col>
                    )}
                  </Row>
                </Spin>
              )}

            {[UserTypes.CHANNEL_PARTNER, UserTypes.SUB_CHANNEL_PARTNER].includes(user_type) &&
              propertyDta &&
              !propertyDta.isLoading &&
              propertyDta.data?.result?.length > 0 &&
              Object.keys(filterValues).length < 1 && (
                <Spin spinning={loading} size="large">
                  <Card className="box_shadow mt-3"></Card>
                </Spin>
              )}

            {propertyId && !propertyDta.isLoading && !is_subscribed && !isFreeTrailUser && (
              <Alert
                className="mt-3"
                message="Subscription"
                description={
                  [UserTypes.USER, UserTypes.TAXACCOUTANT, UserTypes.ADMIN_SUB_TAXACCOUNTANT].includes(user_type)
                    ? 'Kindly subscribe for this property.'
                    : 'Kindly ask your client or super admin to subscribe for this property.'
                }
                type="warning"
                showIcon
                action={
                  [UserTypes.USER, UserTypes.TAXACCOUTANT, UserTypes.ADMIN_SUB_TAXACCOUNTANT].includes(user_type) ? (
                    <Space>
                      <Button
                        type="primary"
                        onClick={() => {
                          navigate(isTaxAccountant ? '/clients/client/subscription/checkout' : '/subscription/checkout', { state: { fromSameSite: true } });
                        }}
                      >
                        Subscribe
                      </Button>
                    </Space>
                  ) : null
                }
              />
            )}
            {Object.keys(filterValues).length > 0 && (
              <div className="record_wrapper mt-3">
                <Spin spinning={loading} size="large">
                  {reportCategoryData.length > 0 && (filterValues.report_type === 2 || filterValues.report_type === 1) && (
                    <Table
                      dataSource={reportCategoryData}
                      pagination={false}
                      loading={loading}
                      size="small"
                      scroll={{
                        x: 'max-content',
                        y: 'calc(100vh - 210px)',
                      }}
                      tableLayout="auto"
                      rowKey={() => crypto.randomUUID()}
                      footer={
                        filterValues.report_type === 1
                          ? () => (
                              <Flex vertical={false} justify="space-between">
                                <b style={{ fontSize: `16px` }}>
                                  Total Net Income of Investment Properties :{' '}
                                  <Text type={grandTotal > 0 ? 'success' : 'danger'}>
                                    <b style={{ fontSize: '16px' }}> {formatCurrency(grandTotal, 2, true) || 0}</b>
                                  </Text>
                                </b>
                                <b style={{ fontSize: `16px` }}>
                                  Total Net Income(Including Owner Occupied Property) :{' '}
                                  <Text type={totalGrandTotal > 0 ? 'success' : 'danger'}>
                                    <b style={{ fontSize: '16px' }}>{formatCurrency(totalGrandTotal, 2, true) || 0}</b>
                                  </Text>
                                </b>
                              </Flex>
                            )
                          : undefined
                      }
                    >
                      <Column
                        title=" "
                        dataIndex="category_name"
                        fixed={'left'}
                        width={250}
                        key="category_name"
                        render={(text, record) =>
                          text || {
                            children: <b> {record.name}</b>,
                            props: {
                              style: { backgroundColor: ['income_title', 'expense_title', 'gst_title', 'less_title'].includes(record.type) ? '#fafafa' : '' },
                            },
                          }
                        }
                      />

                      {reportData?.map((item) => {
                        return filterValues && (filterValues.dateType === 'date' || filterValues.report_type === 2) ? (
                          <Column
                            title={
                              <div style={{ textAlign: 'center' }}>
                                <span className="d-block">
                                  {`${item.propertyData.street_number} ${item.propertyData.street_name}, ${item.propertyData.suburb}, ${item.propertyData.state}, ${item.propertyData.postcode}`}
                                </span>
                                {item?.subscription === false ? (
                                  <>
                                    {[UserTypes.USER, UserTypes.TAXACCOUTANT, UserTypes.ADMIN_SUB_TAXACCOUNTANT].includes(user_type) ? (
                                      <Tag
                                        color="red"
                                        className="cursor-pointer"
                                        onClick={() => {
                                          isTaxAccountant
                                            ? navigate('/clients/client/subscription/checkout', { state: { fromSameSite: true } })
                                            : navigate('/subscription/checkout', { state: { fromSameSite: true } });
                                        }}
                                      >
                                        Subscribe
                                      </Tag>
                                    ) : (
                                      <Popover content="Kindly ask your client or super admin to subscribe for this property.">
                                        <Tag color="red" className="cur-pointer">
                                          Subscribe
                                        </Tag>
                                      </Popover>
                                    )}
                                  </>
                                ) : (
                                  ''
                                )}
                              </div>
                            }
                            key="category_id"
                            align="right"
                            width={140}
                            render={(_, record) => {
                              if (['income_title', 'expense_title', 'gst_title', 'less_title'].includes(record.type)) {
                                return {
                                  children: '',
                                  props: {
                                    style: { borderLeft: 'none', backgroundColor: '#fafafa' },
                                  },
                                };
                              }
                              if (item?.subscription === false) {
                                return {
                                  children: '',
                                  props: {
                                    style: { backgroundColor: '#fafafa' },
                                  },
                                };
                              }
                              if (['purchase_settlement_date', 'property_sell_date', 'construction_date'].includes(record.type)) {
                                return {
                                  children: getValueofReport(record, item, item.propertyData),
                                  props: {
                                    style: { textAlign: 'center' },
                                  },
                                };
                              }
                              return getValueofReport(record, item, item.propertyData);
                            }}
                          />
                        ) : (
                          <ColumnGroup
                            title={
                              <div style={{ textAlign: 'center', display: 'block' }}>
                                <span className="d-block">
                                  {`${item.propertyData.street_number} ${item.propertyData.street_name}, ${item.propertyData.suburb}, ${item.propertyData.state}, ${item.propertyData.postcode}`}
                                </span>
                                {item.reportData[0]?.subscription === false ? (
                                  <>
                                    {[UserTypes.USER, UserTypes.TAXACCOUTANT, UserTypes.ADMIN_SUB_TAXACCOUNTANT].includes(user_type) ? (
                                      <Tag
                                        color="red"
                                        className="cur-pointer"
                                        onClick={() => {
                                          isTaxAccountant
                                            ? navigate('/clients/client/subscription/checkout', { state: { fromSameSite: true } })
                                            : navigate('/subscription/checkout', { state: { fromSameSite: true } });
                                        }}
                                      >
                                        Subscribe
                                      </Tag>
                                    ) : (
                                      <Popover content="Kindly ask your client or super admin to subscribe for this property.">
                                        <Tag color="red" className="cur-pointer">
                                          Subscribe
                                        </Tag>
                                      </Popover>
                                    )}
                                  </>
                                ) : (
                                  ''
                                )}
                              </div>
                            }
                          >
                            {item.reportData.map((reportItem) => (
                              <Column
                                title={
                                  <div style={{ textAlign: 'center' }}>
                                    FY {reportItem.year.toString().slice(-2)}-{(Number(reportItem.year) + 1).toString().slice(-2)}
                                  </div>
                                }
                                dataIndex="year"
                                key="year"
                                align="right"
                                width={140}
                                render={(_, record) => {
                                  if (['income_title', 'expense_title', 'gst_title', 'less_title'].includes(record.type)) {
                                    return {
                                      children: '',
                                      props: {
                                        style: { borderLeft: 'none', backgroundColor: '#fafafa' },
                                      },
                                    };
                                  }

                                  if (reportItem?.subscription === false) {
                                    return {
                                      children: '',
                                      props: {
                                        style: { backgroundColor: '#fafafa' },
                                      },
                                    };
                                  }

                                  if (['purchase_settlement_date', 'property_sell_date', 'construction_date'].includes(record.type)) {
                                    if (reportData[0]?.reportData[0]?.year === reportItem.year) {
                                      return {
                                        children: getValueofReport(record, reportData, item.propertyData),
                                        props: {
                                          colSpan: item.reportData.length,
                                          style: { textAlign: 'center' },
                                        },
                                      };
                                    } else {
                                      return {
                                        children: '-',
                                        props: {
                                          colSpan: 0,
                                        },
                                      };
                                    }
                                  }

                                  return getValueofReport(record, reportItem, item.propertyData);
                                }}
                              />
                            ))}
                          </ColumnGroup>
                        );
                      })}
                    </Table>
                  )}
                  {filterValues.report_type === 3 && (
                    <>
                      <Table
                        className="loan-report"
                        dataSource={reportCategoryData}
                        pagination={false}
                        size="small"
                        rowKey={'id'}
                        columns={loan_columns}
                        scroll={{
                          x: true,
                        }}
                      />
                      <div className="loan-report-desc mt-3">
                        <Paragraph>* Loan Type - Principal and Interest Repayment ( P&I) and Interest only Repayment is ( Int only)</Paragraph>
                        <Paragraph>** LVR - Loan to Market Value Ratio</Paragraph>
                      </div>
                    </>
                  )}
                </Spin>
              </div>
            )}

            {/* Select Property Security */}
            <Drawer title="Select Property Security" width={440} destroyOnClose onClose={onChangeSecurityOpen} open={securityValue}>
              <Form
                initialValues={{ securityFormValue: securityValue?.securities ? securityValue?.securities?.map((item) => item.id) : [] }}
                onFinish={(values) => handleLoanReportChange({ loan_id: securityValue.id, property_ids: values.securityFormValue || [] })}
              >
                <Flex justify={'flex-end'}>
                  <Button type="primary" className="mb-3" htmlType="submit">
                    Update
                  </Button>
                </Flex>
                <Form.Item name={'securityFormValue'}>
                  <TreeSelect
                    {...{
                      treeData: propertyData || [],
                      treeCheckable: true,
                      placeholder: 'Select Property',
                      style: {
                        width: '100%',
                      },
                      maxTagCount: 'responsive',
                      treeDefaultExpandAll: true,
                      treeNodeFilterProp: 'title',
                      optionFilterProp: 'children',
                    }}
                  />
                </Form.Item>
              </Form>
            </Drawer>

            {/* Select Fixed Interest Expiry */}
            <Drawer title="Select Fixed Interest Expiry" destroyOnClose onClose={onChangeInterestExpiryOpen} open={interestExpiry}>
              <Form
                initialValues={{ interestExpiryValue: interestExpiry?.fixed_interst_expiry ? dayjs(dayjs(interestExpiry?.fixed_interst_expiry).format('DD/MM/YYYY'), 'DD/MM/YYYY') : null }}
                onFinish={(values) =>
                  handleFixedInterestExpiryChange({ loan_id: interestExpiry.id, fixed_interst_expiry: values.interestExpiryValue ? dayjs(values.interestExpiryValue).format('YYYY/MM/DD') : null })
                }
              >
                <Form.Item name={'interestExpiryValue'}>
                  <DatePicker format={'DD/MM/YYYY'} />
                </Form.Item>
                <Flex justify={'flex-end'}>
                  <Button type="primary" htmlType="submit">
                    Update
                  </Button>
                </Flex>
              </Form>
            </Drawer>

            {/* Error message of subscription */}
            {contextHolder}
          </div>
        </div>
      </main>
      <Drawer title="Transaction List" destroyOnClose size="large" onClose={onCloseTransaction} open={openTransaction}>
        {transactionData[0] && (
          <Descriptions
            className="transmeta"
            column={1}
            bordered={false}
            items={[
              {
                key: '1',
                label: 'Property Name',
                children: `${transactionData[0]?.street_number} ${transactionData[0]?.street_name}, ${transactionData[0]?.suburb}, ${transactionData[0]?.state}, ${transactionData[0]?.postcode}`,
              },
              {
                key: '3',
                label: 'Property Type',
                children: getPropertyTypeLabel(transactionData[0]?.property_type),
              },
              {
                key: '2',
                label: 'Category',
                children: transactionData[0]?.category_name,
              },
            ]}
          ></Descriptions>
        )}
        <DT paddingclassName="mt-2 property_list table_shadow" columns={columns} data={transactionData} rowCount={10} loading={transactionLoading} />

        <Drawer title="Edit Transaction" width={1120} destroyOnClose onClose={onCloseTransactionEdit} open={openTransactionEdit}>
          <EditRecordExpenses tid={transactionEditValue?.id} tstate={transactionEditValue?.state} onUpdateTransaction={onUpdateTransaction} />
        </Drawer>
      </Drawer>
      <DownloadStatementModal openDownloadStaement={openDownloadStaement} onCloseDownloadStaement={onCloseDownloadStaement} />
      <DrawerDepreciation
        drawerOpen={depreciationOpen}
        depreciationPropertyData={depreciationPropertyData}
        setDrawerOpen={setDepreciationOpen}
        propertyId={depreciationPropertyId}
        onUpdateDepreciation={onUpdateDepreciation}
        setDepreciationPropertyData={setDepreciationPropertyData}
        fromReport={true}
        propertyData={transactionData[0]}
        category_id={categoryId}
      />
    </>
  );
}

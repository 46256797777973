import { SettingOutlined } from '@ant-design/icons';
import clients from '../../assets/images/clients.svg';
import onboarding from '../../assets/images/onbording.svg';

import CustomIcon from './custIcon';

const ChannelPartnerNavigationConfig = [
  {
    title: 'Clients',
    path: `clients`,
    icon: <CustomIcon width={20} icon={clients} />,
  },
  {
    title: 'Onboarding',
    path: 'onboarding',
    icon: <CustomIcon width={20} icon={onboarding} />,
  },
  {
    title: 'Settings',
    path: 'settings',
    key: 'menu_user_account',
    icon: <SettingOutlined style={{ fontSize: 20, marginRight: '12px' }} />,
  },
];

export default ChannelPartnerNavigationConfig;

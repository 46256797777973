import React from 'react';
import { Modal } from 'antd';

const ModalOpenCharts = ({ element = null, open = false, setOpen = () => {}, title = null, onClose = () => {} }) => {
  return (
    <Modal
      destroyOnClose
      open={open}
      onCancel={() => {
        setOpen(false);
        onClose();
      }}
      title={title}
      centered
      footer={null}
      width={1200}
    >
      <div className="w-100 h-100 p-3">{element}</div>
    </Modal>
  );
};

export default ModalOpenCharts;

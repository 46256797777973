import { getRequest } from '../../../utils/apiUtils';

export const getRentSummaryChartData = (obj) => {
  const { id, userId } = obj;
  let tempUrl;
  if (userId) {
    tempUrl = `user/properties/rent-data/${id}?userId=${userId}`;
  } else {
    tempUrl = `user/properties/rent-data/${id}`;
  }

  return new Promise(async (resolve, reject) => {
    const { result, error } = await getRequest(tempUrl);
    const data = result;
    if (!error) {
      resolve({ data, error });
    } else {
      reject({ data, error });
    }
  });
};

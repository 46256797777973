import { Button, List, Modal, Popconfirm, Spin, notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import { FrownOutlined, SmileOutlined } from '@ant-design/icons';
import { getTitle, getIcon } from '../common/NotificationUtil';

import { TaxAccountantNo } from '../../redux/actions/login/taxAccountantNo.action';

import { getApproveUser } from '../../redux/actions/dashboard/getApprove.action';
import { getRejectUser } from '../../redux/actions/dashboard/getReject.action';

import NoNotification from '../../assets/images/NoNotification.svg';

import 'react-phone-input-2/lib/style.css';

// import classNames from 'classnames';
import './NoticeList.less';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import ModalAddTaxAccountant from '../../utils/ModalAddTaxAccountant';
import { notApplicableDepreciationAsync } from '../../redux/reducers/slices/Depreciation/DepreciationSlice';
import { removePropertyNotificationDepreciationItem } from '../../redux/reducers/slices/dashboard/getPropertyNotificationData.slice';
import { propertyRemoveDepreciationItem } from '../../redux/reducers/slices/property/getPropertyListById.slice';

const NoticeList = ({ list = [], onReloadHandler, notifiationLoading, notifiationVisible }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [consentValue, setConsentValue] = useState(false);
  const currentClient = localStorage.getItem('currentClient');
  const [spin, setSpin] = useState(false);

  if (!list || list.length === 0) {
    return (
      <div className={'notFound'}>
        <img src={NoNotification} alt="not found" />
        {/* <div>{emptyText}</div> */}
      </div>
    );
  }

  const taxAccountNo = async () => {
    notifiationLoading(true);
    dispatch(TaxAccountantNo()).then((data) => {
      if (data.payload.success === true) {
        notification.open({
          message: 'Success',
          description: 'Tax Accountant Status Updated.',
          icon: (
            <SmileOutlined
              style={{
                color: '#56AAAA',
              }}
            />
          ),
        });
        onReloadHandler();
      } else {
        notifiationLoading(false);
        notification.open({
          message: 'Failure',
          description: data.payload.message || 'Something went wrong!',
          icon: (
            <FrownOutlined
              style={{
                color: 'red',
              }}
            />
          ),
        });
      }
    });
  };

  const handleApprove = (id, type) => {
    notifiationLoading(true);
    setSpin(true);
    dispatch(getApproveUser(id, type)).then((res) => {
      if (res.payload.success === true) {
        notification.open({
          message: 'Success',
          description: res.payload.message,
          icon: (
            <SmileOutlined
              style={{
                color: '#56AAAA',
              }}
            />
          ),
        });
        if (type === 1) {
          setConsentValue(false);
        }
        setSpin(false);
        onReloadHandler();
      } else {
        notifiationLoading(false);
        setSpin(false);
        notification.open({
          message: 'Failure',
          description: res.payload.message || 'Something went wrong!',
          icon: (
            <FrownOutlined
              style={{
                color: 'red',
              }}
            />
          ),
        });
      }
    });
  };

  const handleReject = (id, type) => {
    notifiationLoading(true);
    setSpin(true);
    dispatch(getRejectUser(id, type)).then((res) => {
      if (res.payload.success === true) {
        notification.open({
          message: 'Success',
          description: res.payload.message,
          icon: (
            <SmileOutlined
              style={{
                color: '#56AAAA',
              }}
            />
          ),
        });
        if (type === 1) {
          setConsentValue(false);
        }
        setSpin(false);
        onReloadHandler();
      } else {
        notifiationLoading(false);
        setSpin(false);
        notification.open({
          message: 'Failure',
          description: res.payload.message || 'Something went wrong!',
          icon: (
            <FrownOutlined
              style={{
                color: 'red',
              }}
            />
          ),
        });
      }
    });
  };

  const handleNotApplicable = async (item) => {
    try {
      const res = await dispatch(notApplicableDepreciationAsync({ id: item.id, currentClient }));
      if (res?.payload?.data?.success) {
        notification.success({
          message: 'Success',
          description: 'Depreciation marked as not applicable.',
        });
        dispatch(removePropertyNotificationDepreciationItem({ propertyId: item.property_id, id: item.id }));
        dispatch(propertyRemoveDepreciationItem(item.id));
        onReloadHandler();
      } else {
        notification.error({
          message: 'Failure',
          description: res?.payload?.data?.message || 'Something went wrong!',
        });
      }
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error.message || 'Something went wrong!',
      });
    }
  };

  const renderItem = (item) => {
    if (item.type === 'rent_summary') {
      return (
        <div key={crypto.randomUUID()} className="my-3 my-sm-0">
          {item.id ? (
            <Button size="small" onClick={() => navigate(`/rental-summary/${item.id}`, { state: { fromSameSite: true } })}>
              Review
            </Button>
          ) : (
            ''
          )}
        </div>
      );
    }
    if (item.type === 'add_property') {
      return (
        <div key={crypto.randomUUID()} className="my-3 my-sm-0">
          <Button size="small" onClick={() => navigate(`/property/add`, { state: { fromSameSite: true } })}>
            Add
          </Button>
        </div>
      );
    } else if (item.type === 'tax_accountant_request') {
      return (
        <div key={crypto.randomUUID()} className="my-3 my-sm-0">
          <Button
            size="small"
            className="mr-1"
            onClick={() => {
              setConsentValue(item);
              notifiationVisible(false);
            }}
          >
            Approve
          </Button>
          <Popconfirm title="Are you sure!" onConfirm={() => handleReject(item.id, 1)} okText="Yes, Sure" cancelText="No">
            <Button size="small" onClick={(e) => e.stopPropagation()}>
              Cancel
            </Button>
          </Popconfirm>
        </div>
      );
    } else if (item.type === 'mortgage_broker_request') {
      return (
        <div key={crypto.randomUUID()} className="my-3 my-sm-0">
          <Button
            size="small"
            className="mr-1"
            onClick={() => {
              setConsentValue(item);
              notifiationVisible(false);
            }}
          >
            Review
          </Button>
        </div>
      );
    } else if (item.type === 'check_tax_accountant') {
      return (
        <div key={crypto.randomUUID()} className="my-3 my-sm-0">
          <Button
            size="small"
            className="mr-1"
            onClick={() => {
              setIsOpen(true);
              notifiationVisible(false);
            }}
          >
            Yes
          </Button>
          <Popconfirm title="Are you sure!" onConfirm={() => taxAccountNo()} okText="Yes, Sure" cancelText="No">
            <Button size="small" onClick={(e) => e.stopPropagation()}>
              No
            </Button>
          </Popconfirm>
        </div>
      );
    } else if (item.type === 'add_product_request') {
      return (
        <div key={crypto.randomUUID()} className="my-3 my-sm-0">
          <Button
            size="small"
            className="mr-1"
            onClick={() => {
              navigate(`/property/${item.property_id}?tab=depreciation`, {
                state: { fromSameSite: true, transaction_id: item.id },
              });
            }}
          >
            Yes
          </Button>
          <Popconfirm title="Are you sure!" onConfirm={() => handleNotApplicable(item)} okText="Yes, Sure" cancelText="No">
            <Button size="small" onClick={(e) => e.stopPropagation()}>
              No
            </Button>
          </Popconfirm>
        </div>
      );
    } else {
      return '';
    }
  };

  return (
    <div>
      <List
        className={'list'}
        dataSource={list}
        renderItem={(item, i) => {
          // const itemCls = classNames(styles.item, {
          //   [styles.read]: item.read,
          // });
          // eslint-disable-next-line no-nested-ternary
          // const leftIcon = item.avatar ? typeof item.avatar === 'string' ? <Avatar className={styles.avatar} src={item.avatar} /> : <span className={styles.iconElement}>{item.avatar}</span> : null;

          return (
            <List.Item key={item.key || i}>
              <List.Item.Meta
                className={'meta'}
                avatar={getIcon(item?.type)}
                title={
                  <div className={'title'}>
                    {getTitle(item?.type)}
                    <div className={'extra '}>{renderItem(item)}</div>
                  </div>
                }
                description={
                  <div>
                    <div className={'description'}>{item?.message}</div>
                  </div>
                }
              />
            </List.Item>
          );
        }}
      />

      <ModalAddTaxAccountant onReloadHandler={onReloadHandler} isOpen1={isOpen} setIsOpen1={setIsOpen} />

      <Modal
        open={consentValue}
        title={`Consent to Share Your Property Data with ${consentValue?.name}`}
        onCancel={() => setConsentValue(false)}
        onOk={() => handleApprove(consentValue?.id, 1)}
        okText="I Agree"
        footer={(_, { OkBtn }) => (
          <>
            <Popconfirm
              title="Are you sure!"
              onConfirm={() => {
                handleReject(consentValue?.id, 1);
                setConsentValue(false);
              }}
              okText="Yes, Sure"
              cancelText="No"
            >
              <Button>Cancel</Button>
            </Popconfirm>
            <OkBtn />
          </>
        )}
      >
        <Spin spinning={spin}>
          <p>By accepting the Terms & Conditions, you authorize {consentValue?.name} to access and manage specific property-related data necessary for tax purposes.</p>
          <p>This includes:</p>
          <ul>
            <li>Property Details and Reports</li>
            <li>Income, Expenses, and Costs associated with the property (including supporting documents)</li>
            <li>Reports such as Income and Expenses and Cost Base Reports</li>
            <li>Bank Loan Summary</li>
          </ul>
          <p>
            With this consent, {consentValue?.name} is permitted to make additions or modifications to property details and expenses.
            <br /> They may also share this information with their team members for tax preparation and analytical purposes.
            <br /> Rest assured, your data will be handled securely and with utmost confidentiality.
          </p>
        </Spin>
      </Modal>
    </div>
  );
};

export default NoticeList;

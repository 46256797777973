import { Avatar, Button, Card, Col, Modal, notification, Row, Tag, Tooltip, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { GoBackDefault } from '../../common/GoBack';
import { DepreciationMethod, PropertyType, UserTypes } from '../../common/userTypes';
import { useNavigate, useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import { GetPropertyById } from '../../../redux/actions/property/getPropertyListById.action';
import { useDispatch, useSelector } from 'react-redux';
import { ExclamationCircleOutlined, FullscreenOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { getPropertyTypeLabel } from '../../../utils/ConstLabel';
import { DeleteProperty } from '../../../redux/actions/property/deleteProperty.action';
import PropertyNotificationModal from './notificationModal';
import Transactions from '../transactions';
import PropertyBanks from './bank';
import CustomIcon from '../../sidebar/custIcon';
import editIcon from '../../../assets/images/edit.svg';
import deleteIcon from '../../../assets/images/delete.svg';
import propertyDashboard from '../../../assets/images/property.svg';
import { ReactComponent as Priminum } from '../../../assets/images/priminum.svg';
import TransactionsByUserProperty from '../details/Details';
import BankConnection from '../details/clientTab/BankConnection';
import { formatCurrency, getAustralianFinancialYear } from '../../../utils';
import PendingTasks from './PendingTasks';
import Reports from '../reports';
import Documentation from '../details/clientTab/Documentation';
import Depreciation from './Depreciation';
import './Details.css';
import { getRentSummaryChartDataAsync, selectRentChartData, selectRentChartDataLoading } from '../../../redux/reducers/slices/property/RentSummaryChartSlice';
import BarChart from './BarChart';
import ModalOpenCharts from '../../common/ModalOpenCharts';

const { Paragraph } = Typography;

export default function PropertyDetails({ isTaxAccountant = false }) {
  let admin = localStorage.getItem('admin');
  let admin1 = JSON.parse(admin);
  const location = useLocation();
  const [propertyData, setPropertyData] = useState({});
  const [actionItems, setActionItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const currentClient = localStorage.getItem('currentClient');
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fromSameSite = location?.state?.fromSameSite;
  const propertyDataById = useSelector((state) => state.getPropertyListByIdReducer);

  useEffect(() => {
    const actionItems = propertyDataById?.data?.result?.action_item || [];
    setActionItems(actionItems);
  }, [propertyDataById]);

  const [currentNotification, setCurrentNotification] = useState([]);
  const [bankLogos, setBankLogos] = useState([]);

  const [open, setOpen] = useState(false);

  const [activeTabKey, setActiveTabKey] = useState(null);
  const [modal, contextHolder] = Modal.useModal();

  const chartData = useSelector(selectRentChartData);
  const chartdata = chartData?.result || [];
  const cartDataLoading = useSelector(selectRentChartDataLoading);

  const updateQuery = (key, value) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set(key, value);
    navigate(`?${searchParams.toString()}`);
  };

  useEffect(() => {
    const transaction_id = location?.state?.transaction_id;

    if (transaction_id) {
      window.scrollTo(0, document.getElementById('tabsDiv').offsetTop);
    }
  }, [location]);

  useEffect(() => {
    if (propertyData?.loans && propertyData?.loans.length > 0) {
      // Build a temporary array of { logo, bank_name } objects
      let bankLogos = [];
      propertyData.loans.forEach((item) => {
        if (item?.logo?.bank_logo) {
          bankLogos.push({
            logo: item.logo.bank_logo,
            bank_name: item.bank_name,
          });
        }
      });

      // Filter out duplicates by matching logo + bank_name
      const uniqueBankLogos = bankLogos.filter((logoObj, index, self) => index === self.findIndex((t) => t.logo === logoObj.logo && t.bank_name === logoObj.bank_name));

      // Set the unique array in state
      setBankLogos(uniqueBankLogos);
    } else {
      setBankLogos([]);
    }
  }, [propertyData]);

  useEffect(() => {
    setLoading(propertyDataById.isLoading);
    if (id && propertyDataById?.data?.result && propertyDataById.error === false) {
      setPropertyData(propertyDataById?.data?.result || {});
    }
  }, [propertyDataById, id]);

  useEffect(() => {
    const activeTab = new URLSearchParams(location.search).get('tab');

    if (activeTab) {
      setActiveTabKey(activeTab);
      return;
    }

    if (!propertyDataById?.isLoading && Object.keys(propertyData).length > 0 && activeTabKey === null) {
      const hasPendingTasks = propertyData?.action_item?.length > 0;
      setActiveTabKey(hasPendingTasks ? 'pendingTasks' : 'transaction');
    }
  }, [location.search, propertyDataById, propertyData, activeTabKey]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tabFromQuery = searchParams.get('tab');
    if (tabFromQuery) {
      setActiveTabKey(tabFromQuery);
    }
  }, [location.search]);

  useEffect(() => {
    if (id) {
      dispatch(GetPropertyById(id, currentClient));
      dispatch(getRentSummaryChartDataAsync({ id, userId: currentClient }));
    }
  }, [currentClient, dispatch, id]);

  const handleModalOpen = () => {
    setOpen(true);
  };

  const items = [
    {
      key: 'transaction',
      label: 'Transaction',
    },
    {
      key: 'document',
      label: 'Document',
    },
    {
      key: 'loan',
      label: 'Loan',
    },
    {
      key: 'report',
      label: 'Report',
    },
    {
      key: 'depreciation',
      label: 'Depreciation',
    },
  ];

  if (propertyData?.action_item?.length > 0) {
    items.unshift({ key: 'pendingTasks', label: 'Pending Tasks' });
  }

  const DeleteHandleProperty = (id) => {
    setLoading(true);
    dispatch(DeleteProperty(id, currentClient)).then((data) => {
      if (data.payload.success === true) {
        notification.success({
          message: 'Success',
          description: data.payload.message || 'Property Deleted Successfully',
        });
        admin1.user_type === UserTypes.USER ? navigate('/property') : navigate('/clients/client/property-details');
      }
    });
  };

  const clearNotifications = (isReload = false, id = null) => {
    setCurrentNotification([]);
    if (isReload) {
      dispatch(GetPropertyById(id, currentClient));
    }
  };

  const getDepreciationMethod = (key) => {
    return key === DepreciationMethod.PRIME_COST_DEPRECIATION ? 'Prime Cost' : 'Diminishing Value';
  };

  const onDeleteHandle = (record) => {
    modal.confirm({
      title: 'Are you sure you want to delete your property?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      cancelText: 'No',
      content: (
        <div className="desc">
          {record.property_type !== PropertyType.OWNER_OCCUPIED_PROPERTY && record?.stripe_subscription_id ? (
            <Paragraph className="pt-2">Deleting this property will cancel your subscription to this property and permanently delete all property related data and documents.</Paragraph>
          ) : (
            <Paragraph className="pt-2">Deleting this property will delete all property related data and documents.</Paragraph>
          )}
          <Paragraph>We cannot recover or reinstate any deleted information if you change your mind later.</Paragraph>
          <Paragraph>Are you sure you want to proceed with the deletion?</Paragraph>
        </div>
      ),
      onOk: () => {
        DeleteHandleProperty(record.id);
      },
      width: 800,
    });
  };

  const onTabChange = (key) => {
    setActiveTabKey(key);
    updateQuery('tab', key);
  };

  return (
    <main className="page-content">
      <div className="container-fluid bg-dash-back">
        <div className={` ${isTaxAccountant ? '' : 'layout-specing'} `}>
          <div className="add_form-1">
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <GoBackDefault fromSameSite={fromSameSite} fallbackPath={admin1.user_type === UserTypes.USER ? '/property' : '/clients/client/property-details'} />

                <h5 className="m-0"> Property Details </h5>
              </div>
              <div>
                {!isTaxAccountant && (
                  <button onClick={() => navigate('/record-expenses', { state: { fromSameSite: true } })} className="p-0 property_btn1 btn btn-primary px-3 py-2 property_btn1_responsive mr-0">
                    + Add Income / Expense
                  </button>
                )}
              </div>
            </div>
            <div className="d-flex flex-column gap-4 p-0 mt-4">
              <Row gutter={[24, 24]} justify="space-between">
                {/* Property Details Column */}
                <Col span={12} xl={12} lg={24} md={24} sm={24} xs={24}>
                  <Card className="shadow-sm property_list_card flex-fill" style={{ minHeight: '100%' }} loading={loading}>
                    <div className="card-title mb-0">
                      <div className="d-flex gap-4">
                        <Avatar shape="square" style={{ backgroundColor: '#f2f8f8', color: '#56aaaa' }} size="large">
                          <img
                            style={{
                              filter: 'brightness(0.9) invert(0.6) sepia(0.6) hue-rotate(132deg) saturate(200%)',
                            }}
                            width={20}
                            src={propertyDashboard}
                            alt=""
                          />
                        </Avatar>
                        <div className="d-flex justify-content-between w-100">
                          <div>
                            <div className="d-flex mb-2 align-items-center">
                              <strong className="text-decoration-underline property_title">{`${propertyData.street_number}${propertyData.street_name ? ' ' + propertyData.street_name : ''}`}</strong>
                              {propertyData.stripe_subscription_id && <Priminum width={22} className="ml-1" title="Subscribed" />}
                            </div>
                            <strong>{`${propertyData.suburb || ''}${propertyData.state ? ', ' + propertyData.state : ''}${propertyData.postcode ? ' ' + propertyData.postcode : ''}`}</strong>
                            <h6 className="card-subtitle text-secondary mt-2">{getPropertyTypeLabel(propertyData.property_type)}</h6>
                            <div className="mt-2">
                              <label>Depreciation:</label>
                              <strong> {propertyData.depreciation_method ? getDepreciationMethod(propertyData.depreciation_method) : 'N/A'} </strong>
                            </div>
                          </div>

                          <div className="d-flex gap-2">
                            <Button
                              title="Edit"
                              type="primary"
                              icon={<CustomIcon icon={editIcon} />}
                              onClick={() => {
                                isTaxAccountant
                                  ? navigate('/clients/client/property/edit/' + propertyData.id, {
                                      state: { fromSameSite: true },
                                    })
                                  : navigate('/property/edit/' + propertyData.id, {
                                      state: { fromSameSite: true },
                                    });
                              }}
                              size="small"
                              className="card_button"
                            />

                            <Button
                              title="Delete"
                              type="primary"
                              className="Delete_btn card_button"
                              icon={<CustomIcon icon={deleteIcon} />}
                              size="small"
                              onClick={() => onDeleteHandle(propertyData)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card>
                </Col>

                {/* Property Bank Column */}
                <Col span={12} xl={12} lg={24} md={24} sm={24} xs={24}>
                  <Card className="shadow-sm property_list_card bank_list_card flex-fill" style={{ minHeight: '100%' }} loading={loading}>
                    {bankLogos.length > 0 && !propertyDataById.isLoading ? (
                      <>
                        <h6 className="text-muted mb-2">Connected Banks</h6>
                        <div className="mt-3">
                          {bankLogos.map((bank, index) => (
                            <Tooltip key={index} placement="bottomLeft" title={bank.bank_name}>
                              <img src={bank.logo} style={{ height: 31, width: 31, borderRadius: '4px' }} className="img-fluid" alt="" />
                            </Tooltip>
                          ))}
                        </div>
                      </>
                    ) : (
                      <div className="d-flex align-items-center gap-2 w-100 h-100">
                        <div className="d-flex flex-column gap-3 align-items-center justify-content-center w-100">
                          <ExclamationCircleOutlined style={{ fontSize: 32 }} />
                          <h6 className="text-muted">
                            {propertyData?.loan_status ? (
                              admin1.user_type === UserTypes.USER ? (
                                <div className="d-flex flex-column gap-2 justify-content-center align-items-center">
                                  <span>Loan not connected.</span>
                                  <span className="text-decoration-underline cursor-pointer" onClick={() => navigate('/bank', { state: { fromSameSite: true } })}>
                                    Connect now
                                  </span>
                                </div>
                              ) : (
                                <div className="d-flex flex-column gap-2 justify-content-center align-items-center">
                                  <span>Loan not connected. </span>
                                  <span>Please Ask user to connect!</span>
                                </div>
                              )
                            ) : (
                              <div className="d-flex flex-column gap-2 justify-content-center align-items-center">
                                <span>No loan added to this property.</span>
                                <p>
                                  <span
                                    className="text-decoration-underline cursor-pointer"
                                    onClick={() =>
                                      navigate(`${admin1.user_type === UserTypes.USER ? `/property/edit/${id}` : `/clients/client/property/edit/${id}`}`, { state: { fromSameSite: true } })
                                    }
                                  >
                                    Click Here
                                  </span>
                                  &nbsp;to add now
                                </p>
                              </div>
                            )}
                          </h6>
                        </div>
                      </div>
                    )}
                  </Card>
                </Col>
              </Row>

              {propertyData?.property_type === PropertyType.OWNER_OCCUPIED_PROPERTY ? (
                <Row gutter={[16, 16]}>
                  <Col span={12} md={12} lg={8} xl={4}>
                    <Card loading={loading} className="shadow-sm">
                      <div className="d-flex mb-2 justify-content-between">
                        <h6 className="text-muted">Net Income</h6>
                        <Tooltip title={`Net Income (FY ${getAustralianFinancialYear(new Date())})`}>
                          <InfoCircleOutlined className="cursor-pointer pb-2" />
                        </Tooltip>
                      </div>
                      <h5 className="m-0">{formatCurrency(propertyData?.netincome || '0')}</h5>
                    </Card>
                  </Col>
                  <Col span={12} md={12} lg={8} xl={4}>
                    <Card loading={loading} className="shadow-sm">
                      <div className="d-flex mb-2 justify-content-between">
                        <h6 className="text-muted">Rent+</h6>
                        <Tooltip title={`Rent + Other Income (FY ${getAustralianFinancialYear(new Date())})`}>
                          <InfoCircleOutlined className="cursor-pointer pb-2" />
                        </Tooltip>
                      </div>
                      <h5 className="m-0">{formatCurrency(propertyData.cy_income || '0')}</h5>
                    </Card>
                  </Col>
                  <Col span={12} md={12} lg={8} xl={4}>
                    <Card loading={loading} className="shadow-sm">
                      <div className="d-flex mb-2 justify-content-between">
                        <h6 className="text-muted">Expense</h6>
                        <Tooltip title={`Expense (FY ${getAustralianFinancialYear(new Date())})`}>
                          <InfoCircleOutlined className="cursor-pointer pb-2" />
                        </Tooltip>
                      </div>
                      <h5 className="m-0">{formatCurrency(propertyData.cy_expense || '0')}</h5>
                    </Card>
                  </Col>
                  <Col span={12} md={12} lg={8} xl={4}>
                    <Card loading={loading} className="shadow-sm">
                      <h6 className="text-muted mb-3">Market Value</h6>
                      <h5 className="m-0">
                        {propertyData.market_value === 'SOLD' ? (
                          <Tag color="red">SOLD</Tag>
                        ) : parseFloat(propertyData.market_value) > propertyData.admin_market_value ? (
                          formatCurrency(propertyData.market_value || '0')
                        ) : (
                          formatCurrency(propertyData.admin_market_value || '0')
                        )}
                      </h5>
                    </Card>
                  </Col>
                  <Col span={12} md={12} lg={8} xl={4}>
                    <Card loading={loading} className="shadow-sm">
                      <h6 className="text-muted mb-3">Loan</h6>
                      <h5 className="m-0 text-danger">{propertyData.loan === 'Not Available' ? 'N/A' : formatCurrency(propertyData.loan || '0')}</h5>
                    </Card>
                  </Col>
                  <Col span={12} md={12} lg={8} xl={4}>
                    <Card loading={loading} className="shadow-sm">
                      <div className="d-flex mb-2 justify-content-between">
                        <h6 className="text-muted">Net Equity</h6>
                      </div>
                      <h5 className="m-0">{formatCurrency(propertyData.net_equity || '0')}</h5>
                    </Card>
                  </Col>
                  {/* <Col span={12} md={12} lg={8} xl={4}>
                    <Card loading={loading} className="shadow-sm">
                      <div className="d-flex mb-2 justify-content-between">
                        <h6 className="text-muted">LVR</h6>
                        <Tooltip title={`Loan to Value Ratio`}>
                          <InfoCircleOutlined className="cursor-pointer pb-2" />
                        </Tooltip>
                      </div>
                      <h5 className="m-0">{`${propertyData.lvr || propertyData.lvr === '' ? propertyData.lvr + '%' : 'N/A'}`}</h5>
                    </Card>
                  </Col> */}
                </Row>
              ) : (
                <Row gutter={[24, 24]} justify="space-between">
                  {/* Stats Column */}
                  <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                    <div className="d-flex flex-column gap-3 h-100">
                      {/* Financial Metrics */}
                      <Row gutter={[24, 24]}>
                        <Col xs={24} sm={12} md={8}>
                          <Card loading={loading} className="shadow-sm h-100">
                            <div className="d-flex mb-2 justify-content-between">
                              <h6 className="text-muted">Net Income</h6>
                              <Tooltip title={`Net Income (FY ${getAustralianFinancialYear(new Date())})`}>
                                <InfoCircleOutlined className="cursor-pointer" />
                              </Tooltip>
                            </div>
                            <h5 className="m-0">{formatCurrency(propertyData?.netincome || '0')}</h5>
                          </Card>
                        </Col>

                        <Col xs={24} sm={12} md={8}>
                          <Card loading={loading} className="shadow-sm h-100">
                            <div className="d-flex mb-2 justify-content-between">
                              <h6 className="text-muted">Rent+</h6>
                              <Tooltip title={`Rent + Other Income (FY ${getAustralianFinancialYear(new Date())})`}>
                                <InfoCircleOutlined className="cursor-pointer" />
                              </Tooltip>
                            </div>
                            <h5 className="m-0">{formatCurrency(propertyData?.cy_income || '0')}</h5>
                          </Card>
                        </Col>

                        <Col xs={24} sm={12} md={8}>
                          <Card loading={loading} className="shadow-sm h-100">
                            <div className="d-flex mb-2 justify-content-between">
                              <h6 className="text-muted">Expense</h6>
                              <Tooltip title={`Expense (FY ${getAustralianFinancialYear(new Date())})`}>
                                <InfoCircleOutlined className="cursor-pointer" />
                              </Tooltip>
                            </div>
                            <h5 className="m-0">{formatCurrency(propertyData?.cy_expense || '0')}</h5>
                          </Card>
                        </Col>
                      </Row>

                      {/* Property Metrics */}
                      <Row gutter={[24, 24]}>
                        <Col xs={24} sm={12} md={8}>
                          <Card loading={loading} className="shadow-sm h-100">
                            <h6 className="text-muted mb-3">Market Value</h6>
                            <h5 className="m-0">
                              {propertyData?.market_value === 'SOLD' ? (
                                <Tag color="red">SOLD</Tag>
                              ) : parseFloat(propertyData?.market_value) > propertyData?.admin_market_value ? (
                                formatCurrency(propertyData?.market_value || '0')
                              ) : (
                                formatCurrency(propertyData?.admin_market_value || '0')
                              )}
                            </h5>
                          </Card>
                        </Col>

                        <Col xs={24} sm={12} md={8}>
                          <Card loading={loading} className="shadow-sm h-100">
                            <h6 className="text-muted mb-3">Loan</h6>
                            <h5 className="m-0 text-danger">{propertyData?.loan === 'Not Available' ? 'N/A' : formatCurrency(propertyData?.loan || '0')}</h5>
                          </Card>
                        </Col>

                        <Col xs={24} sm={12} md={8}>
                          <Card loading={loading} className="shadow-sm h-100">
                            <h6 className="text-muted mb-3">Net Equity</h6>
                            <h5 className="m-0">{formatCurrency(propertyData?.net_equity || '0')}</h5>
                          </Card>
                        </Col>
                      </Row>
                    </div>
                  </Col>

                  {/* Chart Column */}
                  <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                    <div className="h-100 position-relative">
                      <Card className="shadow-sm rent-summary-graph border-0 h-100" loading={cartDataLoading || loading}>
                        <BarChart chartData={chartdata || []} />
                      </Card>
                      {!(loading && cartDataLoading) && chartdata?.length > 0 && (
                        <Button title="Maximize Graph" icon={<FullscreenOutlined />} className="graph-open-button" onClick={handleModalOpen} />
                      )}
                    </div>
                  </Col>
                </Row>
              )}

              <div className="overflow-hidden" id="tabsDiv">
                <Card
                  style={{
                    width: '100%',
                  }}
                  className={`${activeTabKey === 'report' ? 'reportPropertyCard' : 'shadow-sm'}`}
                  loading={loading}
                  tabList={items}
                  activeTabKey={activeTabKey}
                  onTabChange={onTabChange}
                  tabProps={{
                    size: 'large',
                  }}
                >
                  {activeTabKey === 'loan' && propertyData.loans && (
                    <>{admin1.user_type === UserTypes.USER ? <PropertyBanks propertyId={propertyData.id} /> : <BankConnection propertyId={propertyData.id} />}</>
                  )}

                  {activeTabKey === 'transaction' && (
                    <>
                      {admin1.user_type === UserTypes.USER ? (
                        <Transactions propertyId={propertyData.id} />
                      ) : (
                        <TransactionsByUserProperty propertyId={propertyData.id} isTaxAccountant={true} spanIncrease={true} />
                      )}
                    </>
                  )}

                  {activeTabKey === 'document' && <Documentation propertyId={propertyData.id} individual={true} isTaxAccountant={isTaxAccountant} />}

                  {activeTabKey === 'depreciation' && <Depreciation />}

                  {activeTabKey === 'pendingTasks' && propertyData?.action_item?.length > 0 && (
                    <PendingTasks isTaxAccountant={isTaxAccountant} list={actionItems} clearNotifications={clearNotifications} loading={loading} />
                  )}

                  {activeTabKey === 'report' && (
                    <Reports
                      propertyId={[id]}
                      isTaxAccountant={isTaxAccountant}
                      is_subscribed={propertyData?.stripe_subscription_id || propertyData?.property_type === PropertyType.OWNER_OCCUPIED_PROPERTY || propertyData?.property_sell === 1}
                    />
                  )}
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PropertyNotificationModal list={currentNotification?.action_item} clearNotifications={clearNotifications} loading={loading} />
      {contextHolder}
      <ModalOpenCharts element={<BarChart chartData={chartdata || []} height={true} />} title={'Rental Summaries (YTD)'} open={open} setOpen={setOpen} />
    </main>
  );
}

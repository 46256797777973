import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import dayjs from 'dayjs';
import './BarChart.css';

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const BarChart = ({ chartData = [], height = false }) => {
  // 1) Prepare chart labels
  const labels = chartData.map((item) => `${dayjs(item.rent_start_date).format('DD-MM-YYYY')} to ${dayjs(item.rent_end_date).format('DD-MM-YYYY')}`);

  // 2) Convert amounts to numbers
  const amounts = chartData.map((item) => Number(item.amount));

  // 3) Single dataset with scriptable background/border color
  const data = {
    labels,
    datasets: [
      {
        label: '', // We will create two custom legend items instead
        data: amounts,
        // ---- Scriptable background color:
        backgroundColor: (ctx) => {
          const chart = ctx.chart;
          const value = ctx.dataset.data[ctx.dataIndex];

          // Initialize custom toggles if they don’t exist yet
          if (!chart.config._customLegendState) {
            chart.config._customLegendState = {
              showMissing: true,
              showPresent: true,
            };
          }

          const { showMissing, showPresent } = chart.config._customLegendState;

          // If bar = 0 but showMissing=false => hide (transparent)
          if (value === 0 && !showMissing) return 'transparent';
          // If bar != 0 but showPresent=false => hide (transparent)
          if (value !== 0 && !showPresent) return 'transparent';

          // Otherwise color based on 0 or not
          return value === 0
            ? '#FF0000' // red
            : '#56aaaa'; // blue
        },
        // ---- Scriptable border color:
        borderColor: (ctx) => {
          const chart = ctx.chart;
          const value = ctx.dataset.data[ctx.dataIndex];
          if (!chart.config._customLegendState) {
            chart.config._customLegendState = {
              showMissing: true,
              showPresent: true,
            };
          }
          const { showMissing, showPresent } = chart.config._customLegendState;

          if (value === 0 && !showMissing) return 'transparent';
          if (value !== 0 && !showPresent) return 'transparent';

          return value === 0 ? '#FF0000' : '#56aaaa';
        },
        borderWidth: 1,
        minBarLength: 5, // helps hover on zero-value bars
      },
    ],
  };

  // 4) Chart configuration
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        display: false, // Hide x-axis lines, ticks, and labels
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Rental Income',
        },
      },
    },
    plugins: {
      // Custom legend with TWO items, even though there's only one dataset
      legend: {
        display: true,
        // This kills default dataset toggling & uses our onClick instead
        onClick: null,
        labels: {
          generateLabels: (chart) => {
            if (!chart.config._customLegendState) {
              chart.config._customLegendState = {
                showMissing: true,
                showPresent: true,
              };
            }
            const { showMissing, showPresent } = chart.config._customLegendState;
            // Return 2 items: one for missing rent (red), one for present (blue)
            return [
              {
                text: 'Missing Rent',
                fillStyle: '#FF0000',
                strokeStyle: '#FF0000',
                hidden: !showMissing, // toggles the "strike-through"
                datasetIndex: 0,
              },
              {
                text: 'Rent Summary Present',
                fillStyle: '#56aaaa',
                strokeStyle: '#56aaaa',
                hidden: !showPresent,
                datasetIndex: 0,
              },
            ];
          },
          // Custom click handler for toggling red or blue bars
          onClick: (evt, legendItem, legend) => {
            const chart = legend.chart;
            if (!chart.config._customLegendState) {
              chart.config._customLegendState = {
                showMissing: true,
                showPresent: true,
              };
            }
            const state = chart.config._customLegendState;

            // Toggle the relevant flag
            if (legendItem.text === 'Missing Rent Summary') {
              state.showMissing = !state.showMissing;
            } else {
              state.showPresent = !state.showPresent;
            }

            // Force chart update
            chart.update();
          },
        },
      },
      title: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const i = context.dataIndex;
            const item = chartData[i];
            // Show just the amount (or more info if you want)
            return [`Rent: ${item.amount}`];
          },
        },
      },
    },
  };

  return (
    <div className={`${height ? 'custom-rent-height' : 'bar_chart'}`}>
      <Bar data={data} options={options} className="rent-summary-bar-chart" height={height} />
    </div>
  );
};

export default BarChart;

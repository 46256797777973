import React, { useEffect, useState } from 'react';
import { Chart as ChartJS, LineElement, CategoryScale, LinearScale, PointElement, Tooltip, Legend } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { Card, Col, Row, Select, Button, Empty } from 'antd';
import { FullscreenOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import ModalOpenCharts from '../../../../common/ModalOpenCharts';

ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, Tooltip, Legend);

const LoanChart = ({ auditDataChart = {}, auditDataChartLoading = false }) => {
  const [propertyConfigData, setPropertyConfigData] = useState([]);
  const [loanConfigData, setLoanConfigData] = useState([]);
  const [propertyFilterOption, setPropertyFilterOption] = useState([]);
  const [loanFilterOption, setLoanFilterOption] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [chartData, setChartData] = useState(null);
  const [title, setTitle] = useState(null);
  const [propertyFilterValue, setPropertyFilterValue] = useState([]);
  const [loanFilterValue, setLoanFilterValue] = useState([]);

  const safeArray = (array) => (Array.isArray(array) ? array : []);

  useEffect(() => {
    if (auditDataChart) {
      const propertyData = safeArray(auditDataChart?.interestAmountByProperty);
      const loanData = safeArray(auditDataChart?.interestAmountByAccountNumber);

      // Convert totals to numbers, etc.
      setPropertyConfigData(
        propertyData.map((item) => ({
          ...item,
          amount: Number(item?.total_calculated_loan_balance || 0),
        })),
      );

      setLoanConfigData(
        loanData.map((item) => ({
          ...item,
          created_at: new Date(item?.created_at),
          amount: Number(item?.total_calculated_loan_balance || 0),
        })),
      );

      // Build filter options
      setPropertyFilterOption(
        propertyData
          .map((item) => ({
            label: item?.property_name || 'Unknown Property',
            value: item?.property_name || '',
          }))
          .filter((item, index, self) => self.findIndex((t) => t.value === item.value) === index),
      );

      setLoanFilterOption(
        loanData
          .map((item) => ({
            label: item?.account_number || 'Unknown Account',
            value: item?.account_number || '',
          }))
          .filter((item, index, self) => self.findIndex((t) => t.value === item.value) === index),
      );
    }

    setPropertyFilterValue([]);
    setLoanFilterValue([]);
  }, [auditDataChart]);

  // A new pastel color palette
  const colors = [
    '#FF6384', // Pink
    '#36A2EB', // Light Blue
    '#FFCE56', // Yellow
    '#9966FF', // Purple
    '#FFA1B5', // Light Pink
    '#4BC0C0', // Teal
    '#FF9F40', // Orange
    '#6D9CE8', // Lavender Blue
    '#6C4C7B', // Dark Purple
    '#C9E4CA', // Pastel Green
  ];

  // Modified function to reformat the x-axis labels to "MMM-YY"
  const formatDataForChart = (data, labelField, xField, yField) => {
    const safeData = safeArray(data);

    // 1) Collect all distinct months (YYYY-MM).
    //    For property data, 'postDate' is already in YYYY-MM;
    //    for loan data, parse the date to YYYY-MM using dayjs.
    const allMonthsSet = new Set();
    safeData.forEach((item) => {
      const monthStr = xField === 'postDate' ? item[xField] : dayjs(item[xField]).format('YYYY-MM');
      allMonthsSet.add(monthStr);
    });
    const allMonths = Array.from(allMonthsSet).sort();

    // Convert the "YYYY-MM" strings to the desired "MMM-YY" format, e.g. "Jul-23"
    const displayLabels = allMonths.map((m) => dayjs(m, 'YYYY-MM').format('MMM-YY'));

    // 2) Collect all unique series labels
    const seriesSet = new Set(safeData.map((item) => item[labelField] || 'Unknown'));
    const seriesLabels = Array.from(seriesSet);

    // 3) Build a map of (seriesName -> month -> yValue)
    const lookup = {};
    safeData.forEach((item) => {
      const seriesName = item[labelField] || 'Unknown';
      const monthStr = xField === 'postDate' ? item[xField] : dayjs(item[xField]).format('YYYY-MM');
      if (!lookup[seriesName]) lookup[seriesName] = {};
      lookup[seriesName][monthStr] = item[yField] || 0;
    });

    // 4) Build datasets, aligning each data array with the same sorted months
    const datasets = seriesLabels.map((seriesName, idx) => {
      const dataPoints = allMonths.map((m) => lookup[seriesName][m] || 0);
      return {
        label: seriesName,
        data: dataPoints,
        borderColor: colors[idx % colors.length],
        backgroundColor: colors[idx % colors.length] + '33', // semi-transparent fill
        borderWidth: 2,
        tension: 0.1,
        fill: true,
      };
    });

    return { labels: displayLabels, datasets };
  };

  const handlePropertyFilterChange = (selectedValues) => {
    // If "all" is selected, replace with every property option value.
    if (selectedValues.includes('all')) {
      const allProperties = propertyFilterOption.map((option) => option.value);
      setPropertyFilterValue(allProperties);
      // Show all data by using the full data set from auditDataChart.
      setPropertyConfigData(
        safeArray(auditDataChart?.interestAmountByProperty).map((f) => ({
          ...f,
          amount: Number(f.total_calculated_loan_balance || 0),
        })),
      );
    } else {
      setPropertyFilterValue(selectedValues);
      const filteredData = safeArray(auditDataChart?.interestAmountByProperty).filter((item) => (selectedValues.length > 0 ? selectedValues.includes(item.property_name) : true));
      setPropertyConfigData(
        filteredData.map((f) => ({
          ...f,
          amount: Number(f.total_calculated_loan_balance || 0),
        })),
      );
    }
  };

  const handleLoanFilterChange = (selectedValues) => {
    if (selectedValues.includes('all')) {
      const allLoans = loanFilterOption.map((option) => option.value);
      setLoanFilterValue(allLoans);
      setLoanConfigData(
        safeArray(auditDataChart?.interestAmountByAccountNumber).map((f) => ({
          ...f,
          created_at: new Date(f.created_at),
          amount: Number(f.total_calculated_loan_balance || 0),
        })),
      );
    } else {
      setLoanFilterValue(selectedValues);
      const filteredData = safeArray(auditDataChart?.interestAmountByAccountNumber).filter((item) => (selectedValues.length > 0 ? selectedValues.includes(item.account_number) : true));
      setLoanConfigData(
        filteredData.map((f) => ({
          ...f,
          created_at: new Date(f.created_at),
          amount: Number(f.total_calculated_loan_balance || 0),
        })),
      );
    }
  };

  const handleModalOpen = (type) => {
    setModalOpen(true);
    if (type === 'property') {
      setChartData(formatDataForChart(propertyConfigData, 'property_name', 'postDate', 'total_calculated_loan_balance'));
      setTitle('By Property');
    } else if (type === 'loan') {
      setChartData(formatDataForChart(loanConfigData, 'account_number', 'created_at', 'total_calculated_loan_balance'));
      setTitle('By BSB & Account');
    }
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setChartData(null);
    setTitle(null);
  };

  // Make chart responsive, fill container
  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false, // Allow the chart to grow/shrink in height
    plugins: {
      legend: {
        position: 'top',
        labels: {
          boxWidth: 12,
          font: {
            size: 12,
          },
        },
        onClick: () => {},
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          autoSkip: false,
        },
      },
      y: {
        grid: {
          color: '#e0e0e0',
        },
        ticks: {
          callback: (value) => value.toLocaleString(),
        },
      },
    },
  };

  // Immediately computed chart data for on-page charts
  const propertyChartData = formatDataForChart(propertyConfigData, 'property_name', 'postDate', 'total_calculated_loan_balance');
  const loanChartData = formatDataForChart(loanConfigData, 'account_number', 'created_at', 'total_calculated_loan_balance');

  return (
    <>
      <Row gutter={0}>
        <Col xl={12} lg={12} md={24} sm={24} xs={24}>
          <Card
            loading={auditDataChartLoading}
            title="By Property"
            extra={
              <div>
                <Select
                  options={[{ value: 'all', label: '-- All Property --' }, ...propertyFilterOption]}
                  allowClear
                  maxTagCount={1}
                  onChange={handlePropertyFilterChange}
                  value={propertyFilterValue}
                  mode="multiple"
                  placeholder="Filter Properties"
                  style={{ width: 250 }}
                />
                <Button icon={<FullscreenOutlined />} onClick={() => handleModalOpen('property')} style={{ marginLeft: 8 }} />
              </div>
            }
          >
            {propertyConfigData.length ? (
              <div style={{ height: 400 }}>
                <Line data={propertyChartData} options={chartOptions} />
              </div>
            ) : (
              <Empty />
            )}
          </Card>
        </Col>

        <Col xl={12} lg={12} md={24} sm={24} xs={24}>
          <Card
            loading={auditDataChartLoading}
            title="By BSB & Account"
            extra={
              <div>
                <Select
                  allowClear
                  maxTagCount={1}
                  value={loanFilterValue}
                  options={[{ value: 'all', label: '-- All Loans --' }, ...loanFilterOption]}
                  onChange={handleLoanFilterChange}
                  mode="multiple"
                  placeholder="Filter Loans"
                  style={{ width: 250 }}
                />
                <Button icon={<FullscreenOutlined />} onClick={() => handleModalOpen('loan')} style={{ marginLeft: 8 }} />
              </div>
            }
          >
            {loanConfigData.length ? (
              <div style={{ height: 400 }}>
                <Line data={loanChartData} options={chartOptions} />
              </div>
            ) : (
              <Empty />
            )}
          </Card>
        </Col>
      </Row>

      {modalOpen && (
        <ModalOpenCharts
          open={modalOpen}
          onClose={handleModalClose}
          element={
            <div style={{ height: 600 }}>
              <Line data={chartData} options={chartOptions} />
            </div>
          }
          title={title}
        />
      )}
    </>
  );
};

export default LoanChart;

import React, { useEffect, useState } from 'react';
import { Button, Drawer, Modal, Result, Space, Spin, Table, Tag, Typography, message, notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { DownloadOutlined, ExclamationCircleOutlined, SyncOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { GetSubscribePropertyList, CancelSubscription, GetInvoiceReceipt } from '../../../redux/actions/subscription/subscription.action';
import { GetPlanList } from '../../../redux/actions/subscription/getPlanList.action';
import { PaymentStatus, UserStatus, UserTypes, clientKeys } from '../../common/userTypes';
import Pricing from './plan.js';
import '../../../assets/less/index.less';
import { getPaymentStatusLabel, getSubscriptionStatusLabel } from '../../../utils/ConstLabel.js';

const { Paragraph } = Typography;

export default function Subscription({ isTaxAccountant = false }) {
  const [modal, contextHolder] = Modal.useModal();
  const navigate = useNavigate();
  let params = useParams();
  const currentClient = localStorage.getItem('currentClient');
  const admin = localStorage.getItem('admin');
  const { user_type = null, id } = admin ? JSON.parse(admin) : {};

  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [dataLoading, setDataLoading] = useState(false);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [planList, setPlanList] = useState([]);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    defaultPageSize: 10,
    showSizeChanger: true,
  });

  const propertyData = useSelector((state) => state.getSubscribePropertyListReducer);
  const planData = useSelector((state) => state.getPlanListReducer);

  //* API Call
  useEffect(() => {
    if (propertyData && propertyData.data.result && propertyData.error === false) {
      setData(propertyData.data.result?.data);
      setTotal(propertyData.data.result?.total);
    }
  }, [propertyData]);

  useEffect(() => {
    if (planData && planData.data.result && planData.error === false) {
      let plandt = planData.data.result;
      setPlanList(plandt);
    }
  }, [planData]);

  useEffect(() => {
    if (!params.status) {
      const skip = (pagination.current - 1) * pagination.pageSize;
      const limit = pagination.pageSize;
      dispatch(GetSubscribePropertyList(currentClient, skip, limit));
    }
  }, [dispatch, pagination, currentClient, params.status]);

  useEffect(() => {
    if (!params.status) {
      dispatch(GetPlanList(currentClient));
    }
  }, [params.status, dispatch, currentClient]);

  useEffect(() => {
    if (!planData.isLoading && !propertyData.isLoading) {
      setLoading(false);
    }
  }, [planData, propertyData]);

  const reloadPropertyList = () => {
    setDataLoading(true);
    const skip = (pagination.current - 1) * pagination.pageSize;
    const limit = pagination.pageSize;

    dispatch(GetSubscribePropertyList(currentClient, skip, limit)).then((data) => {
      setDataLoading(false);
    });
  };

  const HandleCancelSubscription = (param) => {
    setDataLoading(true);
    dispatch(CancelSubscription(param, currentClient)).then(({ result, error }) => {
      if (!error && result.success === true) {
        const skip = (pagination.current - 1) * pagination.pageSize;
        const limit = pagination.pageSize;
        dispatch(GetSubscribePropertyList(currentClient, skip, limit));
        notification.success({
          message: 'Success',
          description: result.message || 'Subscription Canceled Successfully',
        });
        setDataLoading(false);
      } else {
        notification.error({
          message: 'Failure',
          description: error.message || 'Something went wrong!',
        });
        setDataLoading(false);
      }
    });
  };

  const getInvoiceReceipt = async (id) => {
    try {
      setDataLoading(true);
      const { result, error } = await GetInvoiceReceipt(id, currentClient);

      if (!error && result.success === true) {
        window.open(result.result.receipt_path, '_blank');
      } else {
        message.error('Invoice receipt not found!');
      }
      setDataLoading(false);
    } catch (error) {
      setDataLoading(false);
      console.error('Error occurred while fetching signed URL:');
    }
  };

  const columns = [
    // {
    //   title: 'Property',
    //   dataIndex: 'street_number',
    //   key: 'street_number',
    //   render: (_text, record) => {
    //     return `${record.street_number} ${record.street_name ? record.street_name : ''}`;
    //   },
    // },
    // {
    //   title: 'Property Type',
    //   dataIndex: 'property_type',
    //   key: 'property_type',
    //   render: (_text, record) => {
    //     return getPropertyTypeLabel(record.property_type);
    //   },
    // },
    {
      title: 'Invoice Number',
      dataIndex: 'invoice_no',
      key: 'invoice_no',
      render: (_text, record) => {
        return record?.payment_status === PaymentStatus.PAYMENT_FREE_TRIAL ? '-' : record?.invoice_no ? record?.invoice_no : '-';
      },
    },
    {
      title: 'Start Date',
      dataIndex: 'start_date',
      key: 'start_date',
      render: (_text, record) => {
        return record?.start_date ? dayjs(record?.start_date).format('DD/MM/YYYY') : '-';
      },
    },
    {
      title: 'Due Date',
      dataIndex: 'end_date',
      key: 'end_date',
      render: (_text, record) => {
        return record?.end_date ? dayjs(record?.end_date).format('DD/MM/YYYY') : '-';
      },
    },
    {
      title: 'Paid by',
      dataIndex: 'paid_by',
      key: 'paid_by',
      render: (_text, record) => {
        return record?.created_by_user_name || '-';
      },
    },
    {
      title: 'Payment Status',
      dataIndex: 'payment_status',
      key: 'payment_status',
      render: (text, record) => <span className="d-flex justify-content-center">{getPaymentStatusLabel(text)}</span>,
    },
    {
      title: 'Subscription Status',
      dataIndex: 'subscription_status',
      key: 'subscription_status',
      render: (text) => <span className="d-flex justify-content-center">{text ? getSubscriptionStatusLabel(Number(text)) : '-'}</span>,
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      render: (_text, record) => {
        return (
          <div className="d-flex justify-content-start align-items-center gap-2">
            {/* Show invoice button if invoice_no is present */}
            {record?.invoice_no && <Button title="Download Invoice" icon={<DownloadOutlined />} type="primary" size="large" onClick={() => getInvoiceReceipt(record.subscription_payment_id)} />}

            {/* Show subscription status if present */}
            {record?.subscription_status ? (
              Number(record?.subscription_status) === UserStatus.CANCELED_ACTIVE ? (
                <Tag color="error">Canceled</Tag>
              ) : (user_type === UserTypes.TAXACCOUTANT && record?.created_by_user_id !== Number(currentClient)) || record?.created_by_user_id === id ? (
                Number(record?.subscription_status) === UserStatus.ACTIVE && (
                  <Button type="secondary" className="Delete_btn" size="middle" onClick={() => onCancelHandle(record)}>
                    Cancel
                  </Button>
                )
              ) : Number(record?.subscription_status) === UserStatus.ACTIVE ? (
                <Tag className="m-0" color="success">
                  Subscribed
                </Tag>
              ) : (
                '-'
              )
            ) : null}

            {/* Show '-' if neither invoice nor subscription status is present */}
            {!record?.invoice_no && !record?.subscription_status && '-'}
          </div>
        );
      },
    },
  ];

  const onClose = () => {
    setOpen(false);
  };

  const handleTableChange = (newPagination) => {
    setPagination({
      ...newPagination,
      skip: (newPagination.current - 1) * newPagination.pageSize,
      limit: newPagination.pageSize,
    });
  };

  const onCancelHandle = (record) => {
    modal.confirm({
      title: 'Are you sure you want to cancel your subscription?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      cancelText: 'No',
      content: (
        <div className="desc">
          <Paragraph className="pt-2">We're sorry to see you go.</Paragraph>
          {record?.payment_status !== PaymentStatus.PAYMENT_FREE_TRIAL && (
            <>
              <Paragraph>Before you cancel, please note that you will continue to have access to all premium features until the end of your current subscription period.</Paragraph>
              <Paragraph>Still want to cancel? By clicking 'yes' below, your subscription will not renew at the end of this billing cycle.</Paragraph>
            </>
          )}
          <Paragraph>For any questions or concerns regarding your subscription, reach out to us at contact@thepropertyaccountant.com.au. We're here to assist you!</Paragraph>
        </div>
      ),
      onOk: () => {
        HandleCancelSubscription({
          propertyId: record.id,
          subscriptionId: record.stripe_subscription_id,
        });
      },
      width: 800,
    });
  };

  const fetchData = () => {
    const skip = (pagination.current - 1) * pagination.pageSize;
    const limit = pagination.pageSize;
    dispatch(GetSubscribePropertyList(currentClient, skip, limit));
    dispatch(GetPlanList(currentClient));
  };

  return (
    <>
      {!loading && data?.length > 0 && (
        <main className={user_type === UserTypes.USER ? 'page-content' : ''}>
          <div className={user_type === UserTypes.USER ? 'container-fluid bg-dash-back' : ''}>
            <div className={user_type === UserTypes.USER ? 'layout-specing' : ''}>
              <div className="row align-items-center mt-1">
                <div className="col-xl-6 col-md-6 col-sm-6 col-12">
                  <div className="text-center text-sm-start mb-sm-0 mb-3 mb-sm-0">
                    <h5 className="m-0">Subscription</h5>
                  </div>
                </div>
                <div className="col-xl-6 col-md-6 col-sm-6 col-12">
                  <div className="text-center text-sm-end">
                    <Space>
                      <Button className="property_btn1_reload " icon={<SyncOutlined />} title="Reload" onClick={() => reloadPropertyList()}>
                        Reload
                      </Button>
                      <Button onClick={() => setOpen(true)} className="property_btn" style={{ marginRight: '0' }}>
                        Subscription Plans
                      </Button>

                      <Button
                        onClick={() =>
                          isTaxAccountant ? navigate('/clients/client/subscription/checkout', { state: { fromSameSite: true } }) : navigate('/subscription/checkout', { state: { fromSameSite: true } })
                        }
                        className="property_btn1"
                      >
                        + New Subscription
                      </Button>
                    </Space>
                  </div>
                </div>
              </div>

              <div className="mt-4 property_list table_shadow">
                <Table
                  pagination={{
                    ...pagination,
                    total: total,
                  }}
                  onChange={handleTableChange}
                  columns={columns}
                  dataSource={data}
                  rowKey={'id'}
                  loading={dataLoading || propertyData.isLoading}
                />
              </div>
            </div>
          </div>
        </main>
      )}

      {!loading &&
        data.length < 1 &&
        (isTaxAccountant ? navigate('/clients/client/subscription/checkout', { state: { fromSameSite: true } }) : navigate('/subscription/checkout', { state: { fromSameSite: true } }))}
      {/* {!loading && data.length < 1 && navigate('/subscription/checkout')} */}
      {loading && (
        <main className={user_type === UserTypes.USER ? 'page-content' : ''}>
          <div className={user_type === UserTypes.USER ? 'container-fluid bg-dash-back' : ''}>
            <div className={user_type === UserTypes.USER ? 'layout-specing' : ''}>
              <div className="row align-items-center mt-1">
                <div className="col-12">
                  <div className="m-start mb-sm-0 mb-3 mb-sm-0">
                    <h5 className="m-0">Subscription</h5>
                  </div>
                </div>
                <div className="col-12 mt-3 text-center">
                  <Spin spinning={loading} size="large" />
                </div>
              </div>
            </div>
          </div>
        </main>
      )}

      <Modal
        open={params.status === 'success'}
        destroyOnClose
        maskClosable="false"
        footer={null}
        onCancel={() => {
          navigate(user_type === UserTypes.USER ? '/subscription' : '/clients/client/subscription');
          user_type !== UserTypes.USER && localStorage.setItem('activeKey', clientKeys.SUBSCRIPTION);
          fetchData();
        }}
      >
        <Result
          status="success"
          title="Payment Successful!"
          subTitle="Congratulations! Your subscription payment was processed successfully. You now have access to all premium features."
          extra={[
            <Button
              type="primary"
              key="console"
              onClick={() => {
                navigate(user_type === UserTypes.USER ? '/subscription' : '/clients/client/subscription');
                user_type !== UserTypes.USER && localStorage.setItem('activeKey', clientKeys.SUBSCRIPTION);
                fetchData();
              }}
            >
              View Subscription
            </Button>,
          ]}
        />
      </Modal>

      <Modal
        open={params.status === 'cancel'}
        destroyOnClose
        maskClosable="false"
        footer={null}
        onCancel={() => {
          navigate(user_type === UserTypes.USER ? '/subscription' : '/clients/client/subscription');
          user_type !== UserTypes.USER && localStorage.setItem('activeKey', clientKeys.SUBSCRIPTION);
          fetchData();
        }}
      >
        <Result
          status="error"
          title="Payment Failed"
          subTitle="Oops! It seems there was an issue processing your payment. Please double-check your payment details or try an alternative payment method. If the issue persists, contact our support team for assistance."
          extra={[
            <Button
              type="primary"
              key="console"
              onClick={() => {
                navigate(user_type === UserTypes.USER ? '/subscription' : '/clients/client/subscription');
                user_type !== UserTypes.USER && localStorage.setItem('activeKey', clientKeys.SUBSCRIPTION);
                fetchData();
              }}
            >
              View Subscription
            </Button>,
            <Button
              key="buy"
              onClick={() => {
                navigate(user_type === UserTypes.USER ? '/subscription/checkout' : '/clients/client/subscription/checkout');
              }}
            >
              Buy Subscription
            </Button>,
          ]}
        />
      </Modal>

      <Drawer title="Subscription Plans" placement="right" width={1150} onClose={onClose} open={open}>
        <Pricing planList={planList} isTaxAccountant={isTaxAccountant} />
      </Drawer>
      {/* Error message of subscription */}
      {contextHolder}
    </>
  );
}

import { Tag } from 'antd';
import { CategoryType, DepreciationMethod, DepreciationStatus, PaymentStatus, PropertyType, TrensectionType, UserStatus } from '../components/common/userTypes';

export const getTransactionTypeLabel = (transactionType) => {
  switch (transactionType) {
    case TrensectionType.SETTLEMENT_TYPE_TRANSACTION:
      return 'Settlement Statement';
    case TrensectionType.RENT_TYPE_TRANSACTION:
      return 'Rental Summary';
    case TrensectionType.MANUAL_TYPE_TRANSACTION:
      return 'Direct Entry';
    case TrensectionType.BASIQ_TYPE_TRANSACTION:
      return 'Bank Feed';
    case TrensectionType.DEPRECIATION_SCHEDULE_TYPE_TRANSACTION:
      return 'Depreciation Schedule';
    case TrensectionType.SUBSCRIPTION_TYPE_TRANSACTION:
      return 'Subscription';
    default:
      return '-';
  }
};

export const getDepreciationMethodLabel = (depreciationMethod) => {
  switch (depreciationMethod) {
    case DepreciationMethod.WDV_DEPRECIATION:
      return 'Diminishing Value';
    case DepreciationMethod.PRIME_COST_DEPRECIATION:
      return 'Prime Cost';
    default:
      return '';
  }
};

export const getPropertyTypeLabel = (propertyType) => {
  switch (propertyType) {
    case PropertyType.COMMERCIAL_PROPERTY:
      return 'Commercial - Investment';
    case PropertyType.INVESTMENT_PROPERTY:
      return 'Residential - Investment';
    case PropertyType.OWNER_OCCUPIED_PROPERTY:
      return 'Residential - Owner Occupied';
    default:
      return '-';
  }
};

export const getDepreciationStatusLabel = (status) => {
  switch (status) {
    case DepreciationStatus.DEPRECIATION_PENDING:
      return <Tag color="gold">Pending</Tag>;
    case DepreciationStatus.DEPRECIATION_COMPLETED:
      return <Tag color="green">Completed</Tag>;
    case DepreciationStatus.DEPRECIATION_NOT_APPLICABLE:
      return <Tag color="red">Not Applicable</Tag>;
    default:
      return '-';
  }
};

export const getDepreciationCategoryLabel = (category) => {
  switch (category) {
    case CategoryType.CAPITAL_IMPROVEMENT_CATEGORY_ID:
      return 'Capital Improvement';
    case CategoryType.EQUIPMENT_COST_CATEGORY_ID:
      return 'Equipment Cost';
    default:
      return '-';
  }
};

export const properyTypeOptions = [
  { label: 'Residential - Investment', value: PropertyType.INVESTMENT_PROPERTY },
  { label: 'Residential - Owner Occupied', value: PropertyType.OWNER_OCCUPIED_PROPERTY },
  { label: 'Commercial - Investment', value: PropertyType.COMMERCIAL_PROPERTY },
];

export const depreciationMethodOptions = [
  { label: 'Diminishing Value', value: DepreciationMethod.WDV_DEPRECIATION },
  { label: 'Prime Cost', value: DepreciationMethod.PRIME_COST_DEPRECIATION },
];

export const getSubscriptionStatusLabel = (status) => {
  switch (status) {
    case UserStatus.ACTIVE:
    case UserStatus.CANCELED_ACTIVE:
      return <Tag color="green">Active</Tag>;
    case UserStatus.COMPLETED:
      return <Tag color="red">Expired</Tag>;
    case UserStatus.CANCELED:
      return <Tag color="red">Cancelled</Tag>;
    default:
      return '-';
  }
};

export const getPaymentStatusLabel = (status) => {
  switch (status) {
    case PaymentStatus.PAYMENT_COMPLETE:
      return <Tag color="green">Paid</Tag>;
    case PaymentStatus.PAYMENT_PENDING:
      return <Tag color="blue">Processing</Tag>;
    case PaymentStatus.PAYMENT_FAILED:
    case PaymentStatus.PAYMENT_CHARGE_FAILED:
      return <Tag color="red">Failed</Tag>;
    case PaymentStatus.PAYMENT_FREE_TRIAL:
      return <Tag color="green">Free Trial</Tag>;
    default:
      return '-';
  }
};

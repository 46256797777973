// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width: 423px) {
  .sidebarNavigationParent .ant-menu-inline-collapsed > .ant-menu-item,
  .sidebar-brand .ant-menu-inline-collapsed > .ant-menu-item {
    padding-inline: calc(22.5% - 8px - 4px) !important;
  }
  .sidebarNavigationParent .page-wrapper .sidebar-wrapper .sidebar-brand,
  .sidebar-brand .page-wrapper .sidebar-wrapper .sidebar-brand {
    width: 80px !important;
  }
}
.ant-menu-light > .ant-menu .ant-menu-item.menuReferralCode {
  background: #f2f8f8 !important;
  color: black !important;
}
.ant-menu-light > .ant-menu .ant-menu-item {
  background: white !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/sidebar/homeCss.less"],"names":[],"mappings":"AAEE;EAAA;;IAEI,kDAAA;EAAJ;EAFA;;IAKI,sBAAA;EACJ;AACF;AAGA;EACE,8BAAA;EACA,uBAAA;AADF;AAIA;EACE,4BAAA;AAFF","sourcesContent":[".sidebarNavigationParent,\n.sidebar-brand {\n  @media screen and (max-width: 423px) {\n    .ant-menu-inline-collapsed > .ant-menu-item {\n      padding-inline: calc(22.5% - 8px - 4px) !important;\n    }\n    .page-wrapper .sidebar-wrapper .sidebar-brand {\n      width: 80px !important;\n    }\n  }\n}\n\n.ant-menu-light > .ant-menu .ant-menu-item.menuReferralCode {\n  background: #f2f8f8 !important;\n  color: black !important;\n}\n\n.ant-menu-light > .ant-menu .ant-menu-item {\n  background: white !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
